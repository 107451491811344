import React, { createContext, useState } from 'react';

export const IOSVersionContext = createContext({
  lowerThan15_4: false,
  onLowerVersion: (value) => {},
});

export const IOSVersionProvider = React.memo(({ children }) => {
  const [state, setState] = useState(false);

  const handleLowerVersion = (state) => {
    setState(state);
  };

  return (
    <IOSVersionContext.Provider
      value={{
        lowerThan15_4: state,
        onLowerVersion: handleLowerVersion,
      }}
    >
      {children}
    </IOSVersionContext.Provider>
  );
});
