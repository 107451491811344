import React, { useContext } from 'react';
import { Link } from 'react-scroll';

import { burgerMenuConfig } from '@/components/BurgerMenu/config';
import { IOSVersionContext } from '@/context/IOSVersionContext';

import * as S from './components';

export const BurgerMenu = React.memo(({ onCrossClick }) => {
  const { lowerThan15_4 } = useContext(IOSVersionContext);
  return (
    <S.Wrapper
      key="burger"
      initial={{
        width: 0,
      }}
      animate={{
        width: '100vw',
      }}
      exit={{
        width: 0,
      }}
      transition={{
        duration: 0.4,
      }}
    >
      <S.Container
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            opacity: {
              duration: 0.6,
            },
          },
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          duration: 0.2,
        }}
      >
        <S.Cross onClick={onCrossClick} />

        <S.LinksWrapper>
          {burgerMenuConfig.map(({ title, id }) => (
            <S.BLink key={id} lowerThan15_4={lowerThan15_4}>
              <Link to={id}>{title}</Link>
            </S.BLink>
          ))}
        </S.LinksWrapper>

        <S.InfoWrapper>
          <S.Title>ПОДПИСЫВАЙТЕСЬ И СЛЕДИТЕ ЗА НОВОСТЯМИ</S.Title>
        </S.InfoWrapper>

        <S.IconsWrapper>
          <a
            href="https://www.instagram.com/it_station/"
            target="_blank"
            rel="noreferrer"
          >
            <S.Icon />
          </a>

          <a href="https://t.me/ITstation_lab" target="_blank" rel="noreferrer">
            <S.TgIcon />
          </a>
        </S.IconsWrapper>

        <S.Rights>© 2022 Все права защищены.</S.Rights>
      </S.Container>
    </S.Wrapper>
  );
});
