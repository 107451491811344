import React from 'react';
import { useMediaQuery } from 'react-responsive';

import * as S from './components';

export const StartInfoLine = React.memo(() => {
  const isMobile = useMediaQuery({ query: '(max-width: 505px)' });
  return (
    <S.Wrapper>
      <div>Старт: апрель</div>
      <div>Занятия: 3 дня в неделю</div>
      <div>{!isMobile && 'Формат:'} онлайн</div>
    </S.Wrapper>
  );
});
