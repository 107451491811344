import CircleType from 'circletype';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-scroll';

import flash1 from '@/assets/images/flashes/main-flash-1.png';
import flash2 from '@/assets/images/flashes/main-flash-2.png';
import mainImage2 from '@/assets/images/mainImage2.webp';
import mainImage from '@/assets/images/mainImage.webp';
import { CurveText } from '@/components/CurveText';
import { IOSVersionContext } from '@/context/IOSVersionContext';
import { iOSVersion } from '@/helpers/getiOSVersion';
import { useScrollPosition } from '@/hooks/useScrollPosition';

import * as S from './components';

export const Main = React.memo(() => {
  const { onLowerVersion } = useContext(IOSVersionContext);

  const scrollPosition = useScrollPosition();
  const offset = 65 + (scrollPosition / 9000) * 60;
  const reverseOffset = 65 - (scrollPosition / 9000) * 60;

  const isLaptopM = useMediaQuery({ query: '(max-width: 750px)' });
  const isLaptop = useMediaQuery({ query: '(max-width: 650px)' });
  const isLaptopS = useMediaQuery({ query: '(max-width: 560px)' });
  const isLaptopXS = useMediaQuery({ query: '(max-width: 500px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 450px)' });

  let INITIAL_DEVOPS_Y_POSITION = -1480;
  let INITIAL_DEVOPS_X_POSITION = 110;
  let INITIAL_PROGRAM_Y_POSITION = -1400;
  let INITIAL_PROGRAM_X_POSITION = -70;
  let WIDTH = 1980;
  let HEIGHT = 2260;
  let RADIUS = 930;

  if (isLaptopM) {
    INITIAL_DEVOPS_Y_POSITION = -1550;
    INITIAL_DEVOPS_X_POSITION = -50;
    INITIAL_PROGRAM_X_POSITION = -200;
  }
  if (isLaptop) {
    INITIAL_DEVOPS_Y_POSITION = -1650;
    INITIAL_PROGRAM_Y_POSITION = -1500;
  }
  if (isLaptopS) {
    INITIAL_PROGRAM_X_POSITION = -150;
  }
  if (isLaptopXS) {
    INITIAL_DEVOPS_X_POSITION = -280;
    INITIAL_DEVOPS_Y_POSITION = -1340;
    INITIAL_PROGRAM_Y_POSITION = -1300;
    INITIAL_PROGRAM_X_POSITION = -400;
    RADIUS = 530;
  }
  if (isMobile) {
    INITIAL_DEVOPS_Y_POSITION = -950;
    INITIAL_DEVOPS_X_POSITION = -425;
    INITIAL_PROGRAM_Y_POSITION = -900;
    INITIAL_PROGRAM_X_POSITION = -490;
    HEIGHT = 1730;
    WIDTH = 1730;
    RADIUS = 330;
  }

  const PROGRAM_TITLE_ANIMATION = {
    y: INITIAL_PROGRAM_Y_POSITION,
    x: INITIAL_PROGRAM_X_POSITION,
  };
  const DEVOPS_TITLE_ANIMATION = {
    y: INITIAL_DEVOPS_Y_POSITION,
    x: INITIAL_DEVOPS_X_POSITION,
  };
  useEffect(() => {
    onLowerVersion(iOSVersion());
  }, [onLowerVersion]);

  useLayoutEffect(() => {
    new CircleType(document.getElementById('circle'));
  }, []);

  return (
    <S.Section>

      <a
        href="https://meet.itstation-lab.com"
        target="_blank"
        rel="noreferrer"
      >
        <S.Button>РЕГИСТРАЦИЯ НА БЕСПЛАТНЫЙ ОНЛАЙН ПРАКТИКУМ</S.Button>
      </a>

      <S.MainContainer>
        <S.RevertDescription>
          Решайте реальные задачи, учитесь работать в команде и осваивайте самые
          актуальные DevOps практики и инструменты
        </S.RevertDescription>
        <S.ImageWrapper>
          <Link to="getStarted" smooth={true}>
            <S.Circle id={'circle'}>
              у з н а т ь у з н а т ь у з н а т ь у з н а т ь у з н а т ь у з н
              а т ь
            </S.Circle>
          </Link>

          <div>
            <S.DevopsTitle
              style={{
                transform: `translateX(${DEVOPS_TITLE_ANIMATION.x}px) translateY(${DEVOPS_TITLE_ANIMATION.y}px)`,
              }}
            >
              <CurveText
                id={1}
                width={WIDTH}
                height={HEIGHT}
                radius={RADIUS}
                reverse={true}
                offset={`${offset}%`}
              >
                devops
              </CurveText>
            </S.DevopsTitle>

            <S.ProgramTitle
              style={{
                transform: `translateX(${PROGRAM_TITLE_ANIMATION.x}px) translateY(${PROGRAM_TITLE_ANIMATION.y}px)`,
              }}
            >
              <CurveText
                id={2}
                width={WIDTH}
                height={HEIGHT}
                radius={RADIUS}
                reverse={true}
                offset={`${reverseOffset}%`}
                second={true}
              >
                program
              </CurveText>
            </S.ProgramTitle>
          </div>

          <S.FirstImage src={mainImage} alt="mainImage" />
          <S.SecondMainImage src={mainImage2} alt="mainImage" />
          <S.BackgroundFlash src={flash1} alt="flash-image" />
          <S.SecondBackgroundFlash src={flash2} alt="flash-image2" />
        </S.ImageWrapper>

        <S.Description>
          Решайте реальные задачи, учитесь работать в команде и осваивайте самые
          актуальные DevOps практики и инструменты
        </S.Description>
      </S.MainContainer>
    </S.Section>
  );
});
