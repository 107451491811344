export const burgerMenuConfig = [
  {
    title: 'О Курсе',
    id: 'course',
  },
  {
    title: 'Как проходит обучение',
    id: 'education',
  },
  {
    title: 'Наша команда',
    id: 'ourTeam',
  },
  {
    title: 'Программа обучения',
    id: 'program',
  },
  {
    title: 'Пакеты обучения',
    id: 'packages',
  },
  {
    title: 'Отзывы',
    id: 'feedbacks',
  },
];
