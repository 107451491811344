import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import footerFlash from '@/assets/images/flashes/ourteam-footer-mobile-flash.png';
import { Container } from '@/components/Container';
import { mentorConfig } from '@/components/OurTeam/config';

import * as S from './components';

export const OurTeam = React.memo(() => {
  const [isActiveId, setIsActiveId] = useState(0);
  const currentMentor = mentorConfig.filter(
    ({ mentorId }) => mentorId === isActiveId,
  );
  const currentMentorImage = currentMentor[0].fullImage;
  const currentMentorName = currentMentor[0].name;
  const currentMentorDescription = currentMentor[0].description;
  const currentMentorSocialLink = currentMentor[0].socialLink;
  const currentMentorSocialIcon = currentMentor[0].socialIcon;
  const currentMentorYoutube = currentMentor[0].youtube;

  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 1100px)' });
  const handleClick = (id) => {
    setIsActiveId(id);
  };
  return (
    <S.Section id="ourTeam">
      <Container>
        <S.Article>Ментор следующего набора</S.Article>

        <AnimatePresence>
          <S.Wrapper>
            <S.ImagesWrapper>
              {mentorConfig.map(({ image, mentorId }) => {
                const active = mentorId === isActiveId;
                return (
                  <S.TeamWrapper key={mentorId}>
                    {/* <S.SmallImage
                      $active={active}
                      onClick={() => handleClick(mentorId)}
                      image={image}
                    /> */}
                  </S.TeamWrapper>
                );
              })}
            </S.ImagesWrapper>

            <S.AboutMentorWrapper>
              {!isLaptopOrMobile && (
                <>
                  <S.BackgroundFlash />
                  <S.SecondBackgroundFlash />
                </>
              )}

              <S.MentorImageWrapper $isActiveId={isActiveId}>
                <S.MentorImage
                  key={currentMentorImage}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 0.76,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  transition={{
                    duration: 0.7,
                  }}
                  src={currentMentorImage}
                  alt="mentorImage"
                />
              </S.MentorImageWrapper>

              <S.InfoWrapper
                initial={{
                  opacity: 0,
                  x: 100,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                  zIndex: 12,
                }}
                exit={{
                  opacity: 0,
                }}
                transition={{
                  type: 'spring',
                  duration: 1,
                }}
                key={isActiveId}
              >
                <S.MentorName>{currentMentorName}</S.MentorName>

                <S.MentorDescription
                  dangerouslySetInnerHTML={{ __html: currentMentorDescription }}
                />

                <S.SocialWrapper>
                  <a
                    href={currentMentorSocialLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <S.SocialIcon src={currentMentorSocialIcon} youtube={currentMentorYoutube}/>
                  </a>
                </S.SocialWrapper>
              </S.InfoWrapper>

              {isLaptopOrMobile && (
                <S.FooterBackgroundFlash src={footerFlash} alt="flash-image" />
              )}
            </S.AboutMentorWrapper>
          </S.Wrapper>
        </AnimatePresence>
      </Container>
    </S.Section>
  );
});
