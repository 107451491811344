export const firstPartOfProgram = [
  {
    quarter: 'Глава I',
    levelTitle: 'Networks. Linux',
    levelDescription: `Вы встречаете своего давнего друга Олега — он с горящими глазами рассказывает о своем участии в стартапе. Впечатлившись вашим интересом к его делу, он соглашается показать «сердце» проекта.
     Перед вами «холодильник» — это и есть тот самый сервер, содержащий приложение, которому лишь предстоит увидеть свет. Админ, увлекшись 
     рассказом, вещает о каком-то Линуксе и Сетях. <p>
     Разбираемся...
     </p>`,
    courseFeatures: [
      {
        featureTitle: 'Встреча менторов со студентами',
        id: 0,
      },
      {
        featureTitle: 'Компьютерные сети',
        featureDescription: `
         <li> Основы сетевых технологий</li>
         <li> Модель OSI </li>
         <li> Протоколы (TCP/IP, HTTP, HTTPS, SSH) </li>
         <li> Порты, маршрутизация, траблшутинг</li>
        `,
        id: 1,
      },
      {
        featureTitle: 'UNIX-системы',
        featureDescription: ` 
          <li>Операционные системы.</li>
          <li>Разновидности Linux</li>
          <li>Ядро, процессы</li>
          <li>Установка (Debian, Ubuntu, CentOS) </li>
          <li>Этапы загрузки </li>
`,
        id: 2,
      },
      {
        featureTitle: 'Linux',
        featureDescription: `
          <li>Дистрибутивы, пакетные менеджеры </li> 
          <li>Работа с командной строкой (работа с файлами)</li> 
          <li>Работа с командной оболочкой (операторы управления переменные, встраиваемые параметры командной оболочки) </li> 
          <li>Программные каналы и команды </li> 
          <li>Управление локальными учетными записями пользователей (группы, профили, пароли пользователей) </li> 
          <li>Механизмы безопасной работы с файлами (права и списки доступа к файлам, ссылки на файлы) </li> 
          <li>Встроенный текстовый редактор Vi</li>`,
        id: 3,
      },
    ],
    backgroundFlash: {
      position: { top: '240px;', right: '35%;' },
      mobilePosition: { right: '-20px;' },
    },
  },
  {
    quarter: 'Глава II',
    levelTitle: 'Git, Bash, Apache, Nginx',
    levelDescription: `Фанат своего дела Олег с радостью делится своей домашней библиотекой и предлагает попробовать свои силы в нетривиальных задачах. К тому же, приглашает вас заглядывать в офис почаще.
      <p>P.S. Похоже, вы приглянулись команде.</p>
    `,
    courseFeatures: [
      {
        featureTitle: 'Git - распределенная система управления версиями',
        featureDescription: `
         <li>Знакомство с системами контроля версий </li>
         <li>Понятия и команды</li>
         <li>Создание веток и репозиториев</li>
         <li>Клонирование, слияние, merge request</li>
        `,
        id: 5,
      },
      {
        featureTitle: 'Bash - командный интерпретатор',
        featureDescription: `
         <li>Командные оболочки</li>
         <li>Внешние и внутренние команды в bash</li>
         <li>Потоки ввода-вывода в bash</li>
         <li>Коды завершения и логические конструкции</li>
         <li>Переменные</li>
         <li>Условия</li>
         <li>Циклы</li>
         <li>Массивы</li>
         <li>Функции / Модули</li>
         <li>SED / AWK</li>
        `,
        id: 5_0,
      },
      {
        featureTitle: 'Apache, Nginx - веб-сервера',
        featureDescription: `
          <li>Понятия www, http/https, dns</li>
          <li>Популярные веб-сервера (nginx, apache):</li>
          <li style='margin-left: 45px'>установка и первичная настройка</li>
          <li style='margin-left: 45px'>конфигурация и настройка</li>
          <li style='margin-left: 45px'>виды применения, балансировка нагрузки</li>
          <li>Тестирование производительности:</li>
          <li style='margin-left: 45px'>инструменты</li>
          <li style='margin-left: 45px'>установка/настройка</li>
          <li>Реальные варианты применения веб-серверов</li>`,
        id: 6,
      },
      {
        featureTitle: 'Сертификаты SSL/TLS',
        featureDescription: `
          <li>Работа с OpenSSL</li>
          <li>Работа с Let's Encrypt</li>
        `,
        id: 7,
      },
    ],
  },
  {
    quarter: 'Глава III',
    levelTitle: 'Python. Ansible ',
    levelDescription: `
    Стартап получает инвестиции! Новый СТО (технический директор), проанализировав весь проект, становится проводником вашей команды в мир девопс-методологий и подходов. В ближайшее время ожидается увеличение нагрузки на приложение и СТО предлагает начать с внедрения еще одного сервера и приведения уже имеющихся в надлежащее состояние.

    <p>Ситуация требует вмешательства системы управления конфигурациями — Ansible.</p>

    <p>P.S. Полученные ранее знания системного администрирования оказались очень кстати.</p>
    `,
    courseFeatures: [
      {
        featureTitle: 'Python для DevOps',
        featureDescription: `
          <li>Знакомство с Python (практическая польза для DevOps)</li>
          <li>Установка окружения</li>
          <li>Типы данных</li>
          <li>Функции</li>
          <li>Пакеты и модули</li>
          <li>Объектно ориентированное программирование (ООП)</li>
        `,
        id: 8,
      },
      {
        featureTitle: `Система управления конфигурациями Ansible`,
        featureDescription: `
          <li>Зачем нужно и зачем не нужно использовать Ansible</li>
          <li>Структура проекта, синтаксис YAML, плейбуки, роли, Ansible vault</li>
          <li>Условия, циклы, рекурсия, handlers</li>
          <li>Jinja2 темплейты</li>
          <li>Ansible best practices</li>
        `,
        id: 9,
      },
      {
        featureTitle: 'Расширенные возможности Ansible',
        featureDescription: `
          <li>Модули для тестов и понятие идемпотентности</li>
          <li>Кастомные модули на Python</li>
          <li>Ansible Galaxy</li>
        `,
        id: 10,
      },
    ],
    backgroundFlash: {
      position: { left: '130px;' },
      mobilePosition: { top: 'unset;', left: '-20px;' },
    },
  },
];

export const secondPartOfProgram = [
  {
    quarter: 'Глава IV',
    levelTitle: 'DBMS',
    levelDescription: `Часть функционала вашего приложения внезапно перестала работать. Новые пользователи проходят регистрацию, но их данные нигде не сохраняются. Олег уверен — проблема в базе данных. Давайте разбираться.`,
    courseFeatures: [
      {
        featureTitle: 'Базы данных (DB)',
        featureDescription: `
          <li>Что же такое БД?</li>
          <li>Модели данных</li>
          <li>Реляционные и нереляционные БД</li>
          <li>Знакомство с различными БД (структура, таблицы, столбцы, ключи)</li>
          <li>Администрирование </li>
          <li>Язык запросов SQL</li>
        `,
        id: 11,
      },
      {
        featureTitle: 'Cистемы управления базами данных  (DBMS)',
        featureDescription: `
          <li>Особенности движков реляционных СУБД</li>
          <li>Установка и настройка MySQL/PostgreSQL </li>
          <li>Настройка (юзеры, порты)</li>
        `,
        id: 12,
      },
    ],
  },
  {
    quarter: 'Глава V',
    levelTitle: 'Docker, Docker-Compose',
    levelDescription: `Оказывается, весь мир шагнул далеко вперед и вместо тяжеловесных монолитных приложений использует микросервисы, что позволяет выйти на следующий уровень отказоустойчивости и доступности.
   <p>*Платформу для практики предоставляет <b>ITstation</b>.</p>
   
`,
    courseFeatures: [
      {
        featureTitle: 'Docker',
        featureDescription: `
          <li>Введение в микросервисную архитектуру </li>
          <li>Установка Docker</li>
          <li>Основные команды для работы с Docker </li>
          <li>Docker volumes </li>
          <li>Networks in Docker </li>
          <li>Работа с Dockerfile</li>
          <li>Управление артефактами (Docker Registry, NPM Repo, PIP, Compos</li>
        `,
        id: 15,
      },
      {
        featureTitle: 'Docker-Compose',
        featureDescription: `
          <li>Работа с Docker Compose </li>
          <li>Разработка и запуск проекта в Docker</li>
        `,
        id: 16,
      },
    ],
  },
  {
    quarter: 'Глава VI',
    levelTitle: 'Kubernetes',
    levelDescription: `Вместе с разработчиками вы проделали большую работу и всё же смогли разбить монолитное приложение на микросервисы. Но как же ими управлять? На такой случай имеется оркестратор, позволяющий строить распределенные отказоустойчивые системы.
   <p>*Платформу для практики предоставляет <b>ITstation</b>.</p>
   
`,
    courseFeatures: [
      {
        featureTitle: 'Концепция',
        featureDescription: `
          <li>Контейнеры, микросервисная архитектура, stateful & stateless приложения</li>
          <li>Архитектура K8S кластера</li>
          <li>Основные объекты K8S (Pod, replicaset, statefulset, daemonset, deployment, service, ingress etc.)</li>
        `,
        id: 17,
      },
      {
        featureTitle: 'Управление объектами K8S',
        featureDescription: `
          <li>Императивный метод (kubectl) и Декларативный метод (YAML)</li>
          <li>Scheduling (Taints + tolerations, affinity + antiaffinity, nodeName, nodeselector, static pods etc.)</li>
        `,
        id: 18,
      },
      {
        featureTitle: 'Траблшутинг',
        featureDescription: `
          <li>Траблшутинг контейнеров и их сетов</li>
          <li>Сетевой траблшутинг</li>
          <li>Траблшутинг ключевых подсистем кластера (api-server, controller-manager, etcd, kubelet, scheduler, etc…)</li>
        `,
        id: 19,
      },
      {
        featureTitle: 'Helm',
        featureDescription: `
          <li>Helm как обертка для приложений в кубернетис</li>
          <li>Репозитории, готовые Helm-chart-ы</li>
          <li>Создание собственных Helm-chart-ов, управление версиями/релизами</li>
        `,
        id: 19_1,
      },
    ],
  },
  {
    quarter: 'Глава VII',
    levelTitle: 'Мониторинг, алертинг и логирование',
    levelDescription:
      'Приложение внезапно перестает работать. Нужно узнать причину и предотвратить подобные неожиданности в будущем. ',
    courseFeatures: [
      {
        featureTitle:
          'Мониторинг (Prometheus, Grafana, Zabbix) и алертинг (Alertmanager)',
        featureDescription: `
          <li>Zabbix:
            <li style='margin-left: 50px'>мониторинг общего назначения</li>
            <li style='margin-left: 50px'>Zabbix server и Zabbix agent</li>
            <li style='margin-left: 50px'>настройка веб-интерфейса Zabbix </li>
            <li style='margin-left: 50px'>Zabbix template </li>
            <li style='margin-left: 50px'>Чем полезны алерты для DevOps-ов</li>
            <li style='margin-left: 50px'>Виды алертов (куда прилетают, что триггерит)</li>
            <li style='margin-left: 50px'>Система оповещения об инцидентах Zabbix (Gmail, Telegram и др.)</li>
          </li>
          
          <li>Prometheus:
            <li style='margin-left: 50px'>новый виток систем мониторинга</li>
            <li style='margin-left: 50px'>метрики, кастомные метрики, экспортеры</li>
            <li style='margin-left: 50px'>Alertmanager - алерты от Prometheus</li>
            <li style='margin-left: 50px'>Alertmanager - обработка и формирование оповещений (Gmail, Telegram, Slack и др.)</li>
          </li>  
          
          <li>Grafana 
            <li style='margin-left: 50px'>основной инструмент визуализации данных (дашборды, публичные и кастомные) </li>
            <li style='margin-left: 50px'>продвинутое использование </li>
          </li>
          
          <li>Kube-prometheus-stack - мониторинг Kubernetes при помощи Prometheus operator</li>
          
          
        `,
        id: 20,
      },
      {
        featureTitle: 'Логирование',
        featureDescription: `
          <li>Введение в логирование</li>     
          <li>Elasticsearch/Kibana/Logstash</li>     
          <li>Datadog</li>     
        `,
        id: 21,
      },
    ],
  },
  {
    quarter: 'Глава IIX',
    levelTitle: 'AWS/GCP',
    levelDescription: `Вашим приложением активно пользуются люди. Задача —обеспечить стабильную работу приложения по всему миру —ложится на плечи DevOps-инженеров.
      <p>Облачные провайдеры предоставляют широкий спектр услуг облачных вычислений, которые позволяют организациям создавать, развертывать и управлять приложениями и инфраструктурой в облаке. </p>
      <p>P.S. Научимся использовать вычислительные мощности облачных провайдеров Amazon или Google</p>`,
    courseFeatures: [
      {
        featureTitle: 'Облачные провайдеры: кто на рынке всех милее?',
        featureDescription: `
          <li>Что же такое облачные технологии?</li>
          <li>Сравнение ведущих мировых облачных провайдеров </li>
        `,
        id: 23,
      },
      {
        featureTitle: 'GCP - Google Cloud Platform',
        featureDescription: `
          <li>Compute Services: Compute Engine, Kubernetes Engine, App Engine, Cloud Functions</li>
          <li>Storage and Database Services: Cloud Storage, Cloud SQL, Cloud Spanner, Cloud Bigtable, Cloud Datastore</li>
          <li>Networking Services: Virtual Private Cloud (VPC), Cloud Load Balancing, Cloud CDN, Cloud DNS, Cloud Interconnect</li>
          <li>Big Data Services: BigQuery, Cloud Dataflow, Cloud Pub/Sub, Cloud Dataproc, Cloud Datalab</li>
          <li>Machine Learning and AI Services: Cloud AI Platform, Cloud Vision API, Cloud Speech-to-Text, Cloud Natural Language API, Cloud Translation API</li>
          <li>Security and Identity Services: Cloud Identity and Access Management (IAM), Cloud Key Management Service (KMS), Cloud Security Command Center, Cloud Data Loss Prevention (DLP), Cloud Identity-Aware Proxy (IAP)</li>
          <li>Management Tools: Cloud Console, Stackdriver, Cloud Deployment Manager, Cloud Billing</li>
          <li>Internet of Things Services: Cloud IoT Core, Cloud IoT Edge</li>
        `,
        id: 24,
      },
      {
        featureTitle: 'AWS - Amazon Web Service',
        featureDescription: `
          <li>AWS structure: Regions, Availability-Zone</li>
          <li>Permission model: IAM, Roles, Groups, Access Keys</li>
          <li>Network: Virtual Private Cloud (VPC), Subnets, Elastic IP, Endpoint, IPv6, Security Groups, Private/Public IP</li>
          <li>Services overview: Availability</li>
          <li>Security</li>
          <li>Storage Group: 
            <li style='margin-left: 50px'>S3 (programming model, SDK, storage types, API call costs, replications, lifecycle)</li>
            <li style='margin-left: 50px'>EFS (programming model)</li>
            <li style='margin-left: 50px'>FSx</li>
          </li>
          
          <li>Compute group:
            <li style='margin-left: 50px'>EC2 (instance types, rent type, pricing, AMI images, long term rent) </li>
            <li style='margin-left: 50px'>EC2 storage type (classic, SSD, local, pricing)  </li>
            <li style='margin-left: 50px'>EC2 T instance types </li>
            <li style='margin-left: 50px'>Elastic GPU</li>
            <li style='margin-left: 50px'>Autoscale: Elastic Load Balancing (types, pricing), creating AMI images, health check</li>
          </li>
          
          <li>Lambda (serverless model, examples, pricing, limitations, invocation types, layers, custom engines)</li>
          <li>Elastic Cloud Kubernetes (EKS), Fargate</li>
          <li>Relational Database Service (RDS)</li>
          <li>CloudFront (creating distribution, manage ssl)</li>
          <li>Route53</li>
          <li>SSM (ops management, change management, node management etc)</li>
        `,
        id: 25,
      },
    ],
  },
  {
    quarter: 'Глава IX',
    levelTitle: 'Terraform',
    levelDescription: ` Теперь вам не придется вручную создавать инстансы, сети и т.д. — достаточно написать конфигурацию, в которой будет изложено, как вы видите вашу будущую инфраструктуру. Также больше не нужно писать документацию к созданной инфраструктуре, т.к.Terraform код по сути таковой и является.`,
    courseFeatures: [
      {
        featureTitle: 'Инфраструктура как код',
        featureDescription: `
          <li>Виды инструментов IaC</li>
          <li>Terraform</li>
        `,
        id: 23,
      },
      {
        featureTitle: 'Основы Terraform',
        featureDescription: `
          <li>.tf файлы, их назначение и структура</li>
          <li>Провайдеры и переменные в Terraform</li>
          <li>Зависимость ресурсов, списки и циклы</li>
          <li>Основные команды Terraform, local & remote state</li>
        `,
        id: 24,
      },
      {
        featureTitle: 'Модули, Terragrunt',
        featureDescription: `
          <li>Существующие модули Terraform, terraform registry, вызов модулей</li>
          <li>Сборка и вызов собственных модулей</li>
          <li>Terragrunt это надстройка для Terraform и какие проблемы решает?</li>
        `,
        id: 25,
      },
    ],
    backgroundFlash: {
      position: { left: '-300px', top: '-465px' },
      mobilePosition: { top: 'unset;', left: '-20px;' },
    },
  },

  {
    quarter: 'Глава X',
    levelTitle:
      'Continuous Integration, Continuous Delivery и Continuous Deployment',
    levelDescription: `
        Стабильная версия уже крутится в облаке, но проблемы с вашим приложением никуда не уходят: все чаще в продакшене приложение работает плохо, постоянно приходят заявки на исправления. Задачи копятся, а о выпуске нового функционала и стабильности не идёт и речи.
        <p>Отныне развертывание ПО перестало быть сложным. Вашей команде больше не нужно тратить несколько дней на подготовку к релизу.</p>
        <p>*Ресурсы предоставляет ITstation.</p>
      `,
    courseFeatures: [
      {
        featureTitle: 'Основные понятия',
        featureDescription: `
          <li>Кто такие CI и СD, и чем они полезны. Continuous Delivery и Continuous Deployment</li>
          <li>Пайплайн, основные элементы и типичная структура</li>
          <li>Популярные инструменты CI/CD</li>
          <li>Git workflow, branching strategy/CD</li>
        `,
        id: 26,
      },
      {
        featureTitle: 'Jenkins',
        featureDescription: `
          <li>Установка и знакомство с Jenkins. Плагины, первые пайплайны</li>
          <li>Виды ранеров Jenkins, переменные, секреты</li>
          <li>Разбор и реализация полноценного пайплайна</li>
          <li>Best Practices</li>
          `,
        id: 27,
      },
      {
        featureTitle: 'GitHub Actions',
        featureDescription: `
        <li>Знакомство с GitHub Actions, простой пайплайн, тригеры, бранчи</li>
        <li>Виды ранеров GitHub Actions, переменные, секреты</li>
        <li>Разбор и реализация полноценного пайплайна</li>
        <li>Best Practices</li>
        `,
        id: 28,
      },
      {
        featureTitle: 'GitlabCI',
        featureDescription: `
        <li>Разбор pipeline в Gitlab</li>
        <li>Виды раннеров Gitlab, переменные, секреты</li>
        <li>Разбор и реализация полноценного пайплайна</li>
        <li>Best Practices</li>
        `,
        id: 28_1,
      },
    ],
    backgroundFlash: {
      position: { left: '235px', top: '-565px' },
      mobilePosition: { top: 'unset;', left: '-20px;' },
    },
  },
  {
    levelTitle: 'Занятия вне программы',
    levelDescription: '',
    courseFeatures: [
      {
        featureTitle: 'Проблематика DevOps',
        featureDescription: `
          <li>Функциональные колодцы</li>
          <li>Нисходящая спираль</li>
          <li>Over-engineering</li>
          <li>Выбор инструментов из множества схожих по функционалу</li>
        `,
        id: 29,
      },
      {
        featureTitle: 'Гибкие методологии и DevOps-культура',
        featureDescription: `
          <li>Командные коммуникации (Slack, Teams, Discord, Google chat) и системы управления задачами (Jira, Trello, Redmine)</li>
          <li>Современные методологии разработки (Agile, Waterfall, Scrum, Kanban). Чем обусловлено их появление?  </li>
          <li>Три пути DevOps: системное мышление, усиление обратной связи, культура эксперименты и обучение</li>
        `,
        id: 30,
      },
      {
        featureTitle: 'Подготовка к собеседованию',
        featureDescription: `
          <li>Поиск подходящей компании и вакансии</li>
          <li>Советы по построению CV, гибкость CV и важность Cover Letter, профиль в LinkedIn</li>
          <li>Темы и вопросы, которые обязательно будут на техническом интервью</li>
          <li>Как стоит и как не стоит себя вести на собеседовании </li>
          <li>Пробные интервью с менторами </li>
        `,
        id: 31,
      },
    ],
  },
  {
    levelTitle: 'Выпускной проект',
    levelDescription: `
     В последнем проекте вам предстоит подготовить инфраструктуру в облаке и настроить конвейер деплоя: системы автоматической сборки, автоматического тестирования и проверки кода на ошибки и уязвимости. Вы выстроите логику развертывания приложения для разных окружений, настроите системы для мониторинга и логирования приложения. 
     <p>Готовый проект следует использовать в качестве кейса для портфолио и показывать при трудоустройстве.</p>
    `,
    courseFeatures: [],
  },
];
