import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Container } from '@/components/Container';
import { courseFeaturesConfig } from '@/components/CourseFeatures/config';

import * as S from './components';

export const CourseFeatures = React.memo(() => {
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  return (
    <Container>
      <S.Wrapper>
        {courseFeaturesConfig.map(
          ({ title, description, specialBlock, specialBlockMobile }, index) => {
            return specialBlock ? (
              <S.LineBlock key={index} />
            ) : (
              <S.FeatureWrapper key={index}>
                <S.FeatureTitle>{title}</S.FeatureTitle>
                {isMobile && specialBlockMobile && <S.LineBlock />}

                <S.FeatureDescription>{description}</S.FeatureDescription>
              </S.FeatureWrapper>
            );
          },
        )}
      </S.Wrapper>
    </Container>
  );
});
