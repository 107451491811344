export const iOSVersion = () => {
  let match = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/),
    version;

  if (match !== undefined && match !== null) {
    version = [
      parseInt(match[1], 10),
      parseInt(match[2], 10),
      parseInt(match[3] || 0, 10),
    ];
    const major = Number(version.join('.').split('.')[0]);
    const minor = Number(version.join('.').split('.')[1]);
    return major < 15 || (major === 15 && minor < 4);
  }
};
