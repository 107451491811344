import { AnimatePresence } from 'framer-motion';
import React, { useContext } from 'react';
import { Portal } from 'react-portal';

import { ApplicationForm } from '@/components/ApplicationForm';
import { ModalContext } from '@/context/ModalContext';

import * as S from './components';

export const Modal = React.memo(() => {
  const { setVisible, visible, learningPackage } = useContext(ModalContext);
  const isModal = true;

  return (
    <Portal>
      <S.Mask
        initial={false}
        animate={{
          opacity: visible ? 1 : 0,
          backdropFilter: 'blur(1.8px)',
          pointerEvents: 'none',
        }}
        transition={{ duration: 0.5 }}
      />

      <AnimatePresence>
        {visible && (
          <S.Modal
            key="modal"
            initial={{
              y: -400,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            exit={{
              x: 2000,
            }}
            transition={{
              duration: 0.4,
            }}
          >
            <S.ModalWrapper>
              <S.Cross onClick={() => setVisible(false)} />
              <S.Title>
                Отправь заявку для участия в практикумах и начни двигаться к карьере
                DevOps-инженера уже сейчас!
              </S.Title>

              <ApplicationForm
                isModal={isModal}
                learningPackage={learningPackage}
                setVisibleModal={setVisible}
              />
            </S.ModalWrapper>
          </S.Modal>
        )}
      </AnimatePresence>
    </Portal>
  );
});
