import styled from 'styled-components/macro';

import inputArrow from '@/assets/icons/inputArrow.svg';
import selectArrow from '@/assets/icons/selectArrow.svg';
import star from '@/assets/icons/star.svg';
import { ButtonWrapper } from '@/components/Button/components';

export const InputsWrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  font-size: 30px;
  box-sizing: border-box;
  border-radius: 40px;
`;
export const RulesWrapper = styled.div`
  display: flex;
`;

export const Rules = styled.div`
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #f2f2f2;
  text-align: start;
  margin-left: 10px;
  max-width: 286px;

  span {
    position: relative;
    color: #eb5757;
  }

  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

export const Checkbox = styled.input`
  width: 20px;
  height: 20px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 44px;
  max-height: 44px;
  max-width: 308px;
  border: 1px solid #cccccc;
  border-radius: 12px;
  margin-bottom: 16px;

  :after {
    content: '';
    background-image: url(${star});
    background-repeat: no-repeat;
    height: 7px;
    width: 30px;
    flex-shrink: 0;
  }

  @media (max-width: 450px) {
    margin-bottom: 12px;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 12px;
  max-width: 308px;
  flex-grow: 1;
  margin-bottom: 16px;

  :after {
    content: '';
    background-image: url(${selectArrow});
    background-repeat: no-repeat;
    height: 7px;
    width: 33px;
    flex-shrink: 0;
  }

  @media (max-width: 450px) {
    margin-bottom: 12px;
  }
`;

export const CommentsWrapper = styled(InputWrapper)`
  min-height: unset;
  max-height: unset;

  :after {
    height: 60px;
  }
`;

export const Comments = styled.textarea`
  font-family: 'Roboto', sans-serif;
  min-height: 92px;
  border: none;
  resize: none;
  background: inherit;
  outline: none;
  padding: 10px 18px 20px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  color: white;
  flex-grow: 1;
`;
export const GetStartedForm = styled.form`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
  z-index: 100;
`;

export const Input = styled.input`
  width: 100%;
  min-height: 44px;
  background-color: inherit;
  outline: none;
  border: none;
  padding: 10px 18px;
  color: white;
  font-size: ${({ theme }) => theme.typography.size[1]};
  box-sizing: border-box;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px #1c1c1c inset;
    -webkit-text-fill-color: white;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  &::placeholder {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #999999;
  }

  @media (max-width: 450px) {
    min-height: 38px;
  }
`;

export const CommentInput = styled(Input)``;

export const ButtonStart = styled(ButtonWrapper)`
  max-height: 49px;
  min-height: 0;
  max-width: 285px;
  margin-top: 40px;
  padding: 14px 93px;
  font-weight: 500;
  font-size: 18px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 450px) {
    margin-top: 30px;
    max-height: 36px;
    font-size: 15px;
  }
`;

export const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const Control = styled.div`
  position: relative;
  background-image: url(${({ controlImage }) => controlImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 23px;
  width: 23px;
  margin-left: 18px;
  cursor: pointer;

  &:after {
    position: absolute;
    display: inline-block;
    content: '';
    width: 12px;
    height: 5px;
    bottom: 8px;
    right: -20px;
    background-image: url(${inputArrow});
    background-repeat: no-repeat;
    cursor: pointer;
  }
`;

export const SocialInput = styled(Input)`
  padding: 10px 18px 10px 40px;
`;

export const MenuWrapper = styled.div`
  position: relative;
`;

export const Menu = styled.div`
  position: absolute;
  bottom: -158px;
  left: -33px;
  min-width: 45px;
  min-height: 86px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: aliceblue;
`;

export const MenuItem = styled(Control)`
  margin-left: 0;
  background-image: url(${({ socialImage }) => socialImage});

  &:after {
    display: none;
  }
`;

export const Select = styled.select`
  min-height: 44px;
  background-color: inherit;
  outline: none;
  border: none;
  padding: 10px 18px;
  font-size: 16px;
  color: white;
  box-sizing: border-box;
  appearance: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  flex-grow: 1;

  option {
    background-color: aliceblue;
    color: black;
  }
`;
