export const devopsConfig = [
  {
    number: '01.',
    title: 'Занятия 3 раза  в неделю',
    description:
      'Интенсивный темп обучения предполагает полное погружение в методологию DevOps',
    maxWidth: '275px',
    custom: 0.1,
  },
  {
    number: '02.',
    title: 'Интенсивное погружение',
    description:
      'Вы будете решать реальные задачи, научитесь работать в команде и освоите самые актуальные DevOps практики и инструменты',
    maxWidth: '325px',
    textAlign: 'center',
    custom: 0.5,
  },
  {
    number: '03.',
    title: 'Помощь с трудоустройством',
    description:
      'После обучения вы сможете получить выгодный оффер в интересных проектах',
    maxWidth: '305px',
    custom: 0.7,
  },
];

export const salaries = [
  {
    position: 'trainee',
    salary: '>700$',
  },
  {
    position: 'junior',
    salary: '700$- 1500$',
  },
  {
    position: 'middle',
    salary: '1500$- 3500$',
  },
  {
    position: 'senior/ lead',
    salary: '3500$- 7000$',
  },
  {
    position: 'architect',
    salary: '5000$- 10000$',
  },
];

export const mobileSalaries = [
  {
    position: 'junior',
    salary: '700$- 1500$',
  },
  {
    position: 'middle',
    salary: '1500$- 3500$',
  },
  {
    position: 'senior/ lead',
    salary: '3500$- 7000$',
  },
  {
    position: 'architect',
    salary: '5000$- 10000$',
  },
];
