import linkedinIcon from '@/assets/icons/linkedin.webp';
import youtube from '@/assets/icons/youtube.webp';
import bogdan from '@/assets/images/bogdan.webp';
import nikita from '@/assets/images/nikita.webp';
import roma from '@/assets/images/roma.webp';
import vladimir from '@/assets/images/vladimir.webp';
import vycheslav from '@/assets/images/vyacheslav.webp';

export const mentorConfig = [
  {
    mentorId: 0,
    name: 'Никита',
    description:
      '8 лет в IT. Окончил BSUIR (Information Security). Обучался DevOps-практикам в Wargaming и EPAM. Последние 5 лет работаю над проектами в области FinTech в стенах EPAM. <p><b>Языки:</b> Bash, Groovy, Node.js, Typescript, Python, C++/C#.</p> Имею различный опыт настройки инфраструктуры и автоматизации развертывания от Ansible, Terraform, Kubernetes до сложных настроек AWS CDK IaC (Python/Typescript).',
    fullImage: nikita,
    //image: nikita,
    socialIcon: linkedinIcon,
    socialLink: 'https://www.linkedin.com/in/nikita-shnipov-1390a01a1/',
  },

];
