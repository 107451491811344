import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

import { Container } from '@/components/Container';
import {
  jobFeatures,
  leftJobFeatures,
  rightJobFeatures,
} from '@/components/JobAssistance/config';

import * as S from './components';

const MobileFeature = ({ title, left, description }) => {
  const { ref, inView } = useInView({
    threshold: 1,
  });
  return (
    <S.FeatureWrapper ref={ref} left={left} inView={inView}>
      <S.Feature>
        <S.FeatureTitle>{title}</S.FeatureTitle>
        <S.FeatureDescription>{description}</S.FeatureDescription>
      </S.Feature>
      {left ? <S.Circle /> : <S.CircleRight />}
    </S.FeatureWrapper>
  );
};

export const JobAssistance = React.memo(() => {
  const isMobile = useMediaQuery({ query: '(max-width: 450px)' });
  return (
    <S.Section viewport={{ once: true }}>
      <S.Title>
        Как вы <span>найдете</span> работу
      </S.Title>
      <Container>
        <S.Features>
          <S.BackgroundFlashWrapper>
            <S.BackgroundFlash />
          </S.BackgroundFlashWrapper>

          {isMobile ? (
            <S.LeftFeatures>
              {jobFeatures.map(({ title, description, left }) => (
                <MobileFeature
                  key={title}
                  title={title}
                  description={description}
                  left={left}
                />
              ))}
            </S.LeftFeatures>
          ) : (
            <>
              <S.LeftFeatures>
                {leftJobFeatures.map(({ title, description }) => (
                  <S.FeatureWrapper key={title}>
                    <S.Feature>
                      <S.FeatureTitle>{title}</S.FeatureTitle>
                      <S.FeatureDescription>{description}</S.FeatureDescription>
                    </S.Feature>
                    <S.Circle />
                  </S.FeatureWrapper>
                ))}
              </S.LeftFeatures>

              <S.RightFeatures>
                {rightJobFeatures.map(({ title, description }) => (
                  <S.FeatureWrapper key={title}>
                    <S.Feature>
                      <S.FeatureTitle>{title}</S.FeatureTitle>
                      <S.FeatureDescription>{description}</S.FeatureDescription>
                    </S.Feature>
                    <S.CircleRight />
                  </S.FeatureWrapper>
                ))}
              </S.RightFeatures>
            </>
          )}
        </S.Features>
      </Container>
    </S.Section>
  );
});
