export const leftJobFeatures = [
  {
    title: 'СОСТАВИМ РЕЗЮМЕ',
    description:
      'Покажем, как грамотно составлять резюме и сопроводительные письма, разберём кейсы и поможем представить Ваши навыки лучшим образом',
  },
  {
    title: 'ДОСТУП К ЗАКРЫТОМУ ЧАТУ',
    description:
      'У вас будет доступ к закрытому чату с вакансиями наших партнёров. Наш чат – это дружное сообщество, которое на связи 24/7 ',
  },
];
export const rightJobFeatures = [
  {
    title: 'Все секреты собеседований',
    description:
      'Мы не просто обучим Вас всем необходимым навыкам для трудоустройства – мы обеспечим Вас полезной информацией о рынке труда и дадим ответы на все карьерные вопросы',
  },
  {
    title: 'РЕКОМЕНДАЦИИ СТУДЕНТОВ',
    description:
      'Мы напишем рекомендательные письма для учеников, которые успешно освоят программу курса – это выделит вас среди других кандидатов',
  },
  {
    title: 'Тренажёр собеседований',
    description:
      'Проведём пробные интервью, чтобы Вы потренировались и получили фидбэк перед реальными собеседованиями',
  },
];

export const jobFeatures = [
  {
    title: 'Все секреты собеседований',
    description:
      'Мы не просто обучим Вас всем необходимым навыкам для трудоустройства – мы обеспечим Вас полезной информацией о рынке труда и дадим ответы на все карьерные вопросы',
  },
  {
    title: 'СОСТАВИМ РЕЗЮМЕ',
    description:
      'Покажем, как грамотно составлять резюме и сопроводительные письма, разберём кейсы и поможем представить Ваши навыки лучшим образом',
    left: true,
  },
  {
    title: 'РЕКОМЕНДАЦИИ СТУДЕНТОВ',
    description:
      'Отберём вакансии среди наших партнёров-работодателей, лучших студентов отправим к ним на стажировку',
  },
  {
    title: 'ДОСТУП К ЗАКРЫТОМУ ЧАТУ',
    description:
      'Вы получите индивидуальную карьерную консультации, а также у' +
      ' Вас будет доступ к закрытому чату с вакансиями наших партнёров. Наш чат – это дружное сообщество, которое на связи 24/7 ',
    left: true,
  },
  {
    title: 'Тренажёр собеседований',
    description:
      'Проведём пробные интервью, чтобы Вы потренировались и получили фидбэк перед “боевыми” собеседованиями',
  },
];
