import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

import cross from '@/assets/icons/cross.svg';

export const Mask = styled(motion.div)`
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
`;

export const Modal = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
  color: white;
`;

export const ModalWrapper = styled.div`
  position: relative;
  width: 735px;
  height: 763px;
  background: #1c1c1c;
  border-radius: 20px;
  padding: 10px 40px 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(180deg, #1d2efd 0%, #4ed3df 100%);
    box-shadow: 0 1px 8px 0 rgba(78, 211, 223, 1),
      0 1px 12px 0 rgba(29, 46, 253, 1);
  }

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    margin: -2px; /* !importanté */
    border-radius: inherit; /* !importanté */
    box-shadow: inset 0 1px 8px 0 rgba(78, 211, 223, 1),
      inset 0 1px 12px 0 rgba(29, 46, 253, 1);
  }

  @media (max-width: 450px) {
    width: 334px;
    height: 535px;
    padding: 8px 18px 16px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const Cross = styled.div`
  width: 40px;
  height: 40px;
  margin-left: auto;
  padding-top: 20px;
  background: url(${cross}) no-repeat center;
  background-size: 28px;
  transform: rotate(45deg);
  cursor: pointer;
  position: relative;
  z-index: 10;

  @media (max-width: 450px) {
    background-size: 14px;
    width: 20px;
  }
`;

export const Title = styled.div`
  text-align: center;
  font-family: 'NAMU', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  max-width: 564px;
  margin-bottom: 55px;
  line-height: 100%;

  @media (max-width: 650px) {
    font-size: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 450px) {
    font-size: 19px;
    margin-bottom: 15px;
  }
`;
