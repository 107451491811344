import React from 'react';
import { Link } from 'react-scroll';

import { Levels } from '@/components/AboutCourse/Levels';
import { Container } from '@/components/Container';
import { CourseFeatures } from '@/components/CourseFeatures';
import { Ticker } from '@/components/Ticker';

import * as S from './components';

export const AboutCourse = React.memo(() => {
  return (
    <S.Section id="course">
      <Container>
        <S.Article>Кому подойдет курс</S.Article>

        <Levels />

        <Link to={'getStarted'} smooth={true}>
          <S.CourseButton>Узнать подробнее</S.CourseButton>
        </Link>
      </Container>

      <Ticker direction={'left'} />
      <Ticker direction={'right'} />

      <CourseFeatures />
    </S.Section>
  );
});
