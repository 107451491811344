import React, { useState } from 'react';

import { Container } from '@/components/Container';
import { Accordion } from '@/components/LearningProgram/Accordion';
import {
  firstPartOfProgram,
  secondPartOfProgram,
} from '@/components/LearningProgram/config';

import * as S from './components';

export const LearningProgram = React.memo(() => {
  const [learningProgram, setLearningProgram] = useState(firstPartOfProgram);
  const [isOpenProgram, setIsOpenProgram] = useState(false);

  const handleToggleProgram = () => {
    if (isOpenProgram) {
      setIsOpenProgram(!isOpenProgram);
      setLearningProgram(firstPartOfProgram);
    } else {
      setIsOpenProgram(true);
      setLearningProgram((prevState) => prevState.concat(secondPartOfProgram));
    }
  };

  return (
    <S.Section id={'program'}>
      <Container>
        <S.Title>Программа обучения</S.Title>

        <S.LevelsWrapper>
          <S.BackgroundFlash />
          <S.SecondBackgroundFlash />
          {isOpenProgram && (
            <>
              <S.ThirdBackgroundFlash />
              <S.FourthBackgroundFlash />
            </>
          )}

          {learningProgram.map(
            ({ courseFeatures, levelDescription, quarter, levelTitle }) => {
              return (
                <S.Level
                  key={levelTitle}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 0.5 } }}
                >
                  <S.LevelQuarter>{quarter}</S.LevelQuarter>

                  <S.LevelWrapper>
                    <S.LevelInfoWrapper>
                      <S.LevelTitle>{levelTitle}</S.LevelTitle>

                      <S.LevelDescription
                        dangerouslySetInnerHTML={{ __html: levelDescription }}
                      />
                    </S.LevelInfoWrapper>

                    <S.CourseWrapper>
                      <Accordion courseFeatures={courseFeatures} />
                    </S.CourseWrapper>
                  </S.LevelWrapper>
                </S.Level>
              );
            },
          )}
        </S.LevelsWrapper>
        <S.ButtonWrapper>
          <S.LearningButton onClick={handleToggleProgram}>
            {isOpenProgram ? 'Скрыть программу' : ' Открыть всю программу'}
          </S.LearningButton>
        </S.ButtonWrapper>
      </Container>
    </S.Section>
  );
});
