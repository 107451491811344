import { useContext } from 'react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Container } from '@/components/Container';
import { englishConfig, packageConfig } from '@/components/PackageCost/config';
import { PackageCostFeaturesMobileLayout } from '@/components/PackageCost/mobileLayout';
import { IOSVersionContext } from '@/context/IOSVersionContext';
import { ModalContext } from '@/context/ModalContext';

import * as S from './components';

export const PackageCost = React.memo(() => {
  const { setVisible, changeLearningPackage } = useContext(ModalContext);
  const { lowerThan15_4 } = useContext(IOSVersionContext);
  const isLaptop = useMediaQuery({ query: '(max-width: 700px)' });

  const handleEnrollInACourse = (learningPackage) => {
    setVisible(true);
    changeLearningPackage(learningPackage);
  };

  return (
    <S.Section id="packages">
      <Container>
        <S.Article>Пакеты обучения</S.Article>
      </Container>

      <S.PackagesWrapper>
        {packageConfig.map(
          ({ title, features, style, price, condition }, index) => (
            <S.PackageWrapper key={index} style={style}>
              <div>
                <S.PackageTitle>{title}</S.PackageTitle>
                {features.map((feature, index) => (
                  <S.PackageFeature key={index} lowerThan15_4={lowerThan15_4}>
                    {feature}
                  </S.PackageFeature>
                ))}
              </div>

              <div>
                <S.PackagePrice>{condition}</S.PackagePrice>
                <S.PackageButton onClick={() => handleEnrollInACourse(title)}>
                   Консультация{' '}
                </S.PackageButton>
              </div>
            </S.PackageWrapper>
          ),
        )}
      </S.PackagesWrapper>

      {isLaptop ? (
        <PackageCostFeaturesMobileLayout />
      ) : (
        <Container>
          <S.EnglishWrapper>
            <S.EnglishTitleWrapper>
              <S.EnglishTitle>Почему английский нужен в IT</S.EnglishTitle>
            </S.EnglishTitleWrapper>

            {englishConfig.map(
              ({
                description,
                title,
                number,
                numberColor,
                titleColor,
                top,
              }) => (
                <S.EnglishFeature key={title}>
                  <S.EnglishFeatureTitle color={titleColor}>
                    {title}
                  </S.EnglishFeatureTitle>

                  <S.EnglishFeatureDescription
                    dangerouslySetInnerHTML={{ __html: description }}
                  />

                  <S.EnglishFeatureNumber color={numberColor} top={top}>
                    {number}
                  </S.EnglishFeatureNumber>
                </S.EnglishFeature>
              ),
            )}

            <S.BackgroundFlash />
          </S.EnglishWrapper>
        </Container>
      )}
    </S.Section>
  );
});
