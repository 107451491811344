import React from 'react';

import image from '@/assets/images/getStartedImage.webp';
import { ApplicationForm } from '@/components/ApplicationForm';
import { Container } from '@/components/Container';

import * as S from './components';

export const GetStarted = React.memo(() => {
  return (
    <S.Section id="getStarted">
      <Container>
        <S.Wrapper>
          <S.BackgroundFlash />

          <S.Article>Измени свою жизнь уже сейчас</S.Article>

          <S.Image src={image} alt="backImage" id="getStarted" />
          <ApplicationForm />
        </S.Wrapper>
      </Container>
    </S.Section>
  );
});
