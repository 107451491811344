import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { Link } from 'react-scroll';

import logo from '@/assets/images/logo.png';
import { Burger } from '@/components/Burger';
import { BurgerMenu } from '@/components/BurgerMenu';

import * as S from './components';

export const Header = React.memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const handleBurgerClick = () => {
    setIsOpen(true);
  };
  const handleCrossClick = () => {
    setIsOpen(false);
  };
  return (
    <S.Header>
      <div>
        <img src={logo} alt="logo" />
      </div>
      <S.Navbar>
        <Link to="education" smooth={true}>
          <S.NavItem>Обучение</S.NavItem>
        </Link>
        <Link to="program" smooth={true}>
          <S.NavItem>Программа</S.NavItem>
        </Link>
        <Link to="packages" smooth={true}>
          <S.NavItem>Пакеты</S.NavItem>
        </Link>
        <Link to="feedbacks" smooth={true}>
          <S.NavItem>Отзывы</S.NavItem>
        </Link>

        <a
          href="https://meet.itstation-lab.com"
          target="_blank"
          rel="noreferrer"
        >
          <S.Button>РЕГИСТРАЦИЯ НА БЕСПЛАТНЫЙ ОНЛАЙН ПРАКТИКУМ</S.Button>
        </a>
      </S.Navbar>
      <Burger onBurgerClick={handleBurgerClick} />

      <AnimatePresence>
        {isOpen && <BurgerMenu onCrossClick={handleCrossClick} />}
      </AnimatePresence>
    </S.Header>
  );
});
