import { useEffect, useState } from 'react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { Container } from '@/components/Container';
import { feedbackConfig } from '@/components/Feedbacks/config';
import { PRESERVE } from '@/constants/framer';

import * as S from './components';

export const Feedbacks = React.memo(() => {
  const [isAnimating, setIsAnimating] = useState(true);
  const [activeId, setActiveId] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 450px)' });
  const isLaptop = useMediaQuery({ query: '(max-width: 780px)' });

  const handleClick = (id) => {
    setActiveId(id);
    setIsAnimating(false);
  };

  useEffect(() => {
    let id = activeId;
    let intervalId;

    if (isAnimating) {
      intervalId = setInterval(() => {
        id === 3 ? (id = 0) : (id += 1);
        setActiveId(id);
      }, 4000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [activeId, isAnimating]);

  return (
    <S.Section id={'feedbacks'}>
      <Container>
        <S.Article>Что говорят наши ученики</S.Article>
        <S.FeedbacksWrapper>
          <S.DescriptionWrapper>
            <S.Quotes>“</S.Quotes>
            <S.FeedbackDescriptionWrapper>
              <S.FeedbackDescription
                onMouseEnter={() => setIsAnimating(false)}
                onMouseLeave={() => setIsAnimating(true)}
                dangerouslySetInnerHTML={{
                  __html: feedbackConfig[activeId].feedback,
                }}
              />
              <S.AvatarsWrapper>
                {feedbackConfig.map(
                  ({ id, description, title, img }, index) => {
                    const active = activeId === index;

                    return (
                      <S.AvatarWrapper
                        key={index}
                        onClick={() => handleClick(index)}
                        layout={PRESERVE}
                        animate={
                          isMobile
                            ? { width: active ? 115 : 39, height: 39 }
                            : isLaptop
                            ? { width: active ? 150 : 60, height: 60 }
                            : {
                                width: active ? 252 : 81,
                                height: active ? 97 : 81,
                              }
                        }
                        transition={{
                          width: { stiffness: 300 },
                          height: { stiffness: 3000 },
                          duration: 0.5,
                        }}
                      >
                        <S.AvatarImage
                          layout={PRESERVE}
                          src={img}
                          alt="mentorImage"
                          animate={
                            isMobile
                              ? {
                                  x: active ? 5 : 0,
                                  width: active ? 33 : 39,
                                  height: active ? 33 : 39,
                                }
                              : isLaptop
                              ? {
                                  x: active ? 5 : 0,
                                  width: active ? 45 : 60,
                                  height: active ? 45 : 60,
                                }
                              : {
                                  x: active ? 15 : 0,
                                  width: 81,
                                  height: 81,
                                }
                          }
                          transition={{
                            x: { stiffness: 30 },
                            duration: 0.5,
                          }}
                        />

                        {active && (
                          <S.AvatarDescriptionWrapper
                            layout={PRESERVE}
                            initial={{ opacity: 0 }}
                            animate={
                              isMobile
                                ? { opacity: 1, x: 0 }
                                : isLaptop
                                ? { x: 0, opacity: 1 }
                                : { opacity: 1, x: 25 }
                            }
                            transition={{ duration: 0.5 }}
                          >
                            <S.AvatarTitle layout={PRESERVE}>
                              {title}
                            </S.AvatarTitle>
                            <S.AvatarDescription layout={PRESERVE}>
                              {description}
                            </S.AvatarDescription>
                          </S.AvatarDescriptionWrapper>
                        )}
                      </S.AvatarWrapper>
                    );
                  },
                )}
              </S.AvatarsWrapper>
            </S.FeedbackDescriptionWrapper>
          </S.DescriptionWrapper>
        </S.FeedbacksWrapper>
      </Container>
    </S.Section>
  );
});
