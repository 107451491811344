import React from 'react';
import Marquee from 'react-fast-marquee';

import * as S from './components';

export const Ticker = React.memo(({ direction }) => {
  return (
    <S.Wrapper>
      <Marquee direction={direction} gradient={false} play={true} >
        cтаньте DevOps-инженером с нуля
      </Marquee>
    </S.Wrapper>
  );
});
