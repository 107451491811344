import instaIcon from '@/assets/icons/insta.svg';
import tgIcon from '@/assets/icons/tg.svg';
import viberIcon from '@/assets/icons/viber.svg';
import whatsappIcon from '@/assets/icons/whatsup.svg';

export const menuItemsConfig = [
  { name: 'telegram', icon: tgIcon, prefix: '@' },
  { name: 'viber', icon: viberIcon, prefix: '+' },
  { name: 'whatsapp', icon: whatsappIcon, prefix: '+' },
  { name: 'insta', icon: instaIcon, prefix: '@' },
];
