import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import arrowImage from '@/assets/icons/arrow.svg';

export const Wrapper = styled.div`
  color: white;
  min-height: 180px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 50px;
  margin-bottom: 90px;

  @media (max-width: 450px) {
    margin-top: 41px;
    margin-bottom: 48px;
  }
`;

export const LevelNumber = styled(motion.div)`
  font-family: Generator;
  font-size: ${({ theme }) => theme.typography.size[7]};
  color: ${({ theme }) => theme.colors.text.darkGrey};

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export const LevelDescription = styled(motion.div)`
  font-weight: 500;
  max-width: 453px;
  font-size: 16px;

  @media (max-width: 1000px) {
    max-width: 400px;
    font-size: 17px;
  }
  @media (max-width: 450px) {
    font-weight: 400;
    font-size: 13px;
    line-height: 110%;
  }
`;

export const LevelsWrapper = styled(motion.div)`
  display: flex;
  gap: 25px;
  align-items: center;

  &:first-child {
    ${LevelNumber} {
      letter-spacing: 1.1px;
    }
  }

  &:nth-child(3) {
    ${LevelDescription} {
      max-width: 378px;
    }
  }

  @media (max-width: 450px) {
    gap: 5px;
  }
`;

export const LevelTitle = styled(motion.div)`
  font-weight: 700;
  font-size: 70px;

  @media (max-width: 1000px) {
    font-size: 45px;
    padding-bottom: 10px;
    max-width: 165px;
  }
  @media (max-width: 800px) {
    font-size: 35px;
  }
  @media (max-width: 450px) {
    font-size: 25px;
    max-width: 120px;
  }
`;

export const ArrowButton = styled.div`
  border-radius: 50%;
  min-width: 112px;
  min-height: 112px;
  border: 3px solid #50dadd;
  background-image: url(${arrowImage});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  ${({ $active }) =>
    $active &&
    css`
      display: none;
    `};

  @media (max-width: 1000px) {
    min-width: 80px;
    min-height: 80px;
  }
  @media (max-width: 600px) {
    min-width: 45px;
    min-height: 45px;
    background-size: 25px;
  }
  @media (max-width: 600px) {
    pointer-events: auto;
  }
`;

export const LevelWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #3a3a3a;
  width: 100%;
  padding: 30px 50px;
  min-height: 180px;
  box-sizing: border-box;

  &:hover {
    background: linear-gradient(
      90deg,
      #4ed3df 3.05%,
      #357fee 35.48%,
      #243a5c 62.35%,
      #1c1c1c 86.3%
    );
  }

  @media (max-width: 1000px) {
    padding: 14px 20px;
    min-height: 135px;

    ${({ $active }) =>
      $active &&
      css`
        flex-direction: column;
        align-items: normal;
      `};
  }
  @media (max-width: 600px) {
    min-height: 98px;
  }
  @media (max-width: 450px) {
    pointer-events: none;
    background: ${({ $active }) =>
      $active
        ? 'linear-gradient(90deg,#4ed3df 3.05%,#357fee 35.48%,#243a5c 62.35%,#1c1c1c 86.3%);'
        : 'unset'};
  }
`;
