export const trainingFeaturesConfig = [
  {
    number: '01',
    title: 'Погрузитесь в сюжет',
    description:
      'Вместе с главным героем — стажёром Максимом — вы пройдете весь путь в стартапе и, как на любом проекте, будете получать задачи от руководителя. Чтобы выполнить каждое задание, у вас будет необходимая теория, материалы, практические кейсы и дедлайн — всё по-настоящему!',
    numberPosition: 'right: 56px',
  },
  {
    number: '02',
    title: 'Практикуетесь и получаете обратную связь',
    description:
      'Вы будете учиться на практике — на задачах, которые спроектированы действующими DevOps-специалистами на основе реальных кейсов. После каждого выполненного задания вы будете получать ревью. Поддержка в чате доступна 24/7.',
  },
  {
    number: '03',
    title: 'Учитесь в группах',
    description:
      'Чтобы координировать процессы между отделами, вам нужно уметь выстраивать коммуникацию. Обучение в небольших группах обеспечит идеальные условия для развития soft-скиллов.',
    numberPosition: 'right: 91px',
  },
  {
    number: '04',
    title: 'Сдаете дипломный проект',
    description:
      'Под управлением менторов вы создадите портфолио архитектурных решений и подходов. В будущем вы сможете осознанно внедрять их в будущих проектах. В конце курса вас ждёт дипломный проект: для его выполнения нужно применить знания всего курса. Готовый проект будет отличным стартом для вашей успешной карьеры.',
  },
  {
    number: '05',
    title: 'Устраиваетесь на работу и проходите испытательный срок',
    description:
      'Менторы помогают даже после окончания обучения. Вы всегда сможете обратиться к эсперту или созвониться с ним, чтобы он дал вам совет и помог преодолеть испытательный срок.',
  },
];
