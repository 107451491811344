import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

import { ArticleTitle } from '@/components/Devops/components';

export const Section = styled.section`
  color: ${({ theme }) => theme.colors.text.main};

  @media (min-width: 1100px) {
    min-height: 590px;
  }
  @media (max-width: 450px) {
    min-height: 440px;
  }
`;

export const Article = styled(ArticleTitle)`
  max-width: 576px;
  @media (max-width: 450px) {
    margin-top: 45px;
    font-size: 45px;
  }
`;

export const FeedbacksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  gap: 50px;

  @media (max-width: 450px) {
    margin-top: 23px;
  }
`;

export const Quotes = styled.div`
  font-family: Generator, sans-serif;
  font-weight: 900;
  font-size: ${({ theme }) => theme.typography.size[16]};

  @media (max-width: 780px) {
    font-size: 100px;
  }

  @media (max-width: 450px) {
    font-size: 60px;
  }
`;

export const FeedbackDescriptionWrapper = styled.div`
  font-size: ${({ theme }) => theme.typography.size[4]};
  color: ${({ theme }) => theme.colors.text.lightGrey};
`;
export const FeedbackDescription = styled(motion.div)`
  position: relative;
  z-index: 10;
  min-height: 304px;
  max-width: 696px;
  margin-bottom: 20px;

  @media (max-width: 830px) {
    max-width: 560px;
  }

  @media (max-width: 780px) {
    font-size: 18px;
    min-height: 230px;
    max-width: 430px;
  }

  @media (max-width: 450px) {
    font-size: 14px;
    max-width: 289px;
    min-height: 150px;
    line-height: 16.8px;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  gap: 35px;
`;

export const AvatarsWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  height: 99px;
  gap: 50px;
  white-space: nowrap;

  @media (max-width: 780px) {
    gap: 20px;
  }

  @media (max-width: 450px) {
    gap: 16px;
    justify-content: flex-end;
  }
`;
export const AvatarImage = styled(motion.img)`
  border-radius: 50%;
`;

export const AvatarWrapper = styled(motion.div)`
  width: 81px;
  height: 81px;
  border-radius: 890px;
  border: 1px solid rgba(254, 111, 95, 1);
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.feedback};
  cursor: pointer;
  box-shadow: 0 0 2px 5px rgba(254, 111, 95, 0.2);

  @media (max-width: 450px) {
    z-index: 10;
  }
`;

export const AvatarDescriptionWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-left: 14px;
  color: black;
`;

export const AvatarTitle = styled(motion.div)`
  font-weight: 600;
  font-size: 23px;
  line-height: 120%;

  @media (max-width: 780px) {
    font-size: 18px;
  }

  @media (max-width: 450px) {
    font-size: 14px;
  }
`;

export const AvatarDescription = styled(motion.div)`
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;

  @media (max-width: 780px) {
    font-size: 11px;
  }

  @media (max-width: 450px) {
    font-size: 8.5px;
  }
`;
