import * as S from './components';

export const Burger = ({ onBurgerClick }) => {
  return (
    <S.BurgerWrap onClick={onBurgerClick} title="burger-button">
      <S.BurgerBar />
      <S.BurgerBar />
      <S.BurgerBar />
    </S.BurgerWrap>
  );
};
