import artem from '@/assets/images/feedbacks/artem-feedback-image.png';
import dmitry from '@/assets/images/feedbacks/dmitry-feedback-image.png';
import nikita from '@/assets/images/feedbacks/nikita-feedback-image.png';
import vyacheslav from '@/assets/images/feedbacks/vyacheslav-feedback-image.png';

export const feedbackConfig = [
  {
    id: 1,
    title: 'Дмитрий',
    description: 'Сентябрь 2021',
    feedback: `
     Понравилась подача информации – качественно проработанные материалы, всё понятно и актуально, <b>без воды</b>. Чувствуется, что менторы варятся в этой сфере, а не просто читают по методичке. По окончании обучения продолжаю поддерживать связь с менторами, всегда дают советы и поддерживают. Самым большим плюсом при обучении была <b>практика на реальных кейсах</b>. Информацию можно нагуглить и бесплатно, а вот практика – бесценна`,
    img: dmitry,
  },
  {
    id: 2,
    title: 'Никита',
    description: 'Январь 2023',
    feedback: `
      Ни капельки не сомневаясь, могу назвать его лучшим обучающим курсом, который я видел. Здесь идеально совпали несколько факторов. <b>Очень порадовала направленность курса на ребят впервые столкнувшись с DevOps</b>. Курс получился в меру теоретическим и в меру практическим. 
      Онлайн-лекции в сочетании с обучающей платформой и качественной обратной связью — всё это сделало ваш курс крайне интересным и крайне полезным. На вопрос «могли бы вы рекомендовать наш курс друзьям» я бы ответил, что <b>уже рекомендовал</b>.
    `,
    img: nikita,
  },
  {
    id: 3,
    title: 'Артём',
    description: 'Март 2022',
    feedback: `
      Поступил на курс для преодоления неизбежного шага из сисадмина в девопс, хотел узнать как пользоваться k8s и terraform, как строить конвейеры деплоя. <b>Оба курса полностью покрыли все мои ожидания и даже больше</b>, были рассмотрены интересные моменты , лектор объяснял все тонкости и главное — логику, чтобы на основе нее реализовывать не шаблонные е задачи.
      В целом курс мне понравился. 

    `,
    img: artem,
  },
  {
    id: 4,
    title: 'Слава',
    description: 'Сентябрь 2021',
    feedback: `
      Крайне благодарен наставнику Владимиру – видно, что человек действительно любит свою профессию, у него горят глаза. Он хотел сделать из нас настоящих специалистов, и у него это точно получилось.
      Огромное спасибо авторам курса за добросовестный труд!
    `,
    img: vyacheslav,
  },
];
