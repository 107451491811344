import React from 'react';

import backgroundImageDevops from '@/assets/images/backgroundDevops.png';
import { mobileSalaries } from '@/components/Devops/config';

import * as S from './components';

export const MobileSalary = () => {
  return (
    <S.Wrapper>
      <S.MBackgroundImage src={backgroundImageDevops} alt="backgroundImage" />

      <S.MSalaryTabletsWrapper>
        {mobileSalaries.map(({ salary, position }, i) => (
          <S.MSalaryTablet index={i} key={salary}>
            <S.MSalaryWrapper index={i}>
              <S.MSalary>{salary}</S.MSalary>
              <S.MPosition>{position}</S.MPosition>
            </S.MSalaryWrapper>
          </S.MSalaryTablet>
        ))}
      </S.MSalaryTabletsWrapper>

      <S.MVacanciesNote>
        * >2000 вакансий DevOps Engineer link hh.ru
      </S.MVacanciesNote>
    </S.Wrapper>
  );
};
