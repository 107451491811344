export const curriculumConfig = [
  {
    number: '1',
    title: 'Применять в работе лучшие практики и принципы DevOps ',
    margin: '-136px',
  },
  {
    number: '2',
    title: 'Работать с микросервисной архитектурой',
    margin: '-64px;',
  },
  {
    number: '3',
    title: 'Автоматизировать процесс разработки и развёртывания приложений',
    margin: '-33px',
  },
  {
    number: '4',
    title:
      'Управлять инфраструктурой используя подход IaC ',
    margin: '-30px',
  },
  {
    number: '5',
    title: 'Сокращать затраты и время на развёртывания и масштабирования',
    margin: '-69px',
  },
  {
    number: '6',
    title:
      'Пользоваться облачными сервисами (AWS/GCP)',
    margin: '-172px',
  },
];

