import { motion } from 'framer-motion';
import styled, { css } from 'styled-components/macro';

import cross from '@/assets/icons/cross.svg';
import instagram from '@/assets/icons/instagram.svg';
import telegram from '@/assets/icons/telegram.svg';

export const Wrapper = styled(motion.div)`
  height: 100vh;
  background: #262626;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100000;
`;
export const Container = styled(motion.div)`
  padding: 20px 20px;
  text-align: center;
  color: white;
`;

export const Cross = styled.div`
  margin-left: auto;
  width: 40px;
  height: 40px;
  background: url(${cross}) no-repeat center;
  background-size: 22px;
  transform: rotate(45deg);
  cursor: pointer;
`;

export const LinksWrapper = styled.ul`
  margin-top: 50px;
`;

export const BLink = styled.li`
  font-size: 16px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  cursor: pointer;

  ${({ lowerThan15_4 }) => {
    return lowerThan15_4
      ? css`
          border-bottom: 0.38px solid #b9b9b9;
        `
      : css`
          &:not(:last-child) {
            border-bottom: 1px solid transparent;
            //TODO
            border-image: radial-gradient(
              97.56% 73428301094.76% at 100% 3426.29%,
              rgba(255, 255, 255, 0) 2.49%,
              #ffffff 52.18%,
              rgba(255, 255, 255, 0) 94.59%
            );
            border-image-slice: 1;
          }
        `;
  }};
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  max-width: 205px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const Icon = styled.div`
  width: 46px;
  height: 46px;
  background-color: #1c1c1c;
  background-image: url('${instagram}');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: 30px;
`;

export const TgIcon = styled(Icon)`
  background-image: url('${telegram}');
  margin-right: 0;
`;

export const Rights = styled.div`
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #999999;
`;
