import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

import { Cross } from '@/components/AboutTraining/components';
import { ArticleTitle } from '@/components/Devops/components';

export const Section = styled.section`
  @media (min-width: 1100px) {
    min-height: 1580px;
  }
`;

export const Title = styled(ArticleTitle)`
  line-height: 57px;

  @media (max-width: 450px) {
    line-height: 38px;
    font-size: 45px;
    margin-bottom: 32px;
  }
`;

export const Level = styled(motion.div)`
  position: relative;
  border-top: 2px solid #3a3a3a;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  z-index: 13;

  @media (max-width: 450px) {
    min-height: 270px;
  }
`;

export const LevelWrapper = styled(motion.div)`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const LevelInfoWrapper = styled(motion.div)`
  max-width: 513px;
  @media (max-width: 1000px) {
    max-width: 766px;
  }
  @media (max-width: 450px) {
    max-width: 280px;
  }
`;

export const LevelQuarter = styled(motion.div)`
  font-weight: 500;
  font-size: 30px;
  color: #292929;
  margin-bottom: 32px;
  margin-top: 8px;

  @media (max-width: 450px) {
    font-size: 14px;
    margin-bottom: 24px;
  }
`;

export const LevelTitle = styled(motion.div)`
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  color: #ffffff;

  @media (max-width: 450px) {
    font-size: 16px;
  }
`;

export const LevelDescription = styled(motion.div)`
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  color: #7a7a7a;
  margin-top: 16px;
  margin-bottom: 32px;

  @media (max-width: 450px) {
    font-size: 15px;
  }
`;

export const LevelProjectTitle = styled(motion.div)`
  font-weight: 700;
  font-size: 23px;
  line-height: 110%;
  color: #ffffff;

  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

export const LevelProjectDescription = styled(LevelDescription)`
  margin-top: 8px;
`;

export const CourseWrapper = styled(motion.div)`
  width: 40%;
  margin-top: -16px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const CourseTitle = styled(LevelTitle)``;

export const CourseFeatureWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #3a3a3a;
  padding-top: 24px;
  padding-bottom: 8px;
  box-sizing: border-box;
`;

export const CourseFeatureTitle = styled(LevelProjectTitle)`
  max-width: 345px;
  position: relative;
  z-index: 10;

  @media (max-width: 450px) {
    font-size: 14px;
    max-width: 300px;
  }
`;
export const FeatureTitleWrapper = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

export const CourseCross = styled(Cross)`
  margin-left: auto;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LearningButton = styled.button`
  border: 1px solid #ffffff;
  background: inherit;
  font-weight: 600;
  font-size: 23px;
  color: #ffffff;
  padding: 14px 143px;
  cursor: pointer;
  position: relative;
  z-index: 13;

  @media (max-width: 450px) {
    font-size: 10px;
    max-width: 232px;
    padding: 14px 44px;
  }
`;

export const CourseFeatureDescription = styled(motion.ul)`
  font-weight: 400;
  font-size: 16px;
  color: #7a7a7a;
  max-width: 350px;
  overflow: hidden;

  li {
    list-style-type: '– ';
    margin-left: 14px;
  }

  @media (max-width: 450px) {
    font-size: 13px;
  }
`;

export const BackgroundFlash = styled.div`
  position: absolute;
  top: 250px;
  right: 35%;
  z-index: -1;
  width: 346px;
  height: 356px;
  border-radius: 449px;
  background: linear-gradient(180deg, #1f95b7 0%, #8123a1 100%);
  filter: blur(130px);
  rotate: 86.423deg;
  transform: translate3d(0, 0, 0);

  @media (max-width: 450px) {
    width: 100vw;
  }
`;

export const SecondBackgroundFlash = styled(BackgroundFlash)`
  top: 1200px;
  right: 130px;
  rotate: unset;
`;

export const ThirdBackgroundFlash = styled(BackgroundFlash)`
  top: 2200px;
  left: 0;
  right: unset;
  rotate: unset;
`;

export const FourthBackgroundFlash = styled(BackgroundFlash)`
  top: 4000px;
  right: 100px;
  rotate: unset;
`;

export const LevelsWrapper = styled.div`
  position: relative;
`;
