import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

import { ArticleTitle } from '@/components/Devops/components';

export const Article = styled(ArticleTitle)`
  @media (max-width: 450px) {
    font-size: 44px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  min-height: 761px;
  position: relative;

  @media (max-width: 1100px) {
    min-height: 830px;
  }
  @media (max-width: 750px) {
    min-height: 714px;
  }
  @media (max-width: 600px) {
    min-height: 577px;
  }
  @media (max-width: 450px) {
    min-height: 260px;
  }
`;

export const TeamWrapper = styled.li`
  display: flex;
  margin-bottom: 32px;

  @media (max-width: 1100px) {
    margin-right: 32px;
  }
  @media (max-width: 900px) {
    margin-right: 20px;
  }
`;

export const SmallImage = styled.div`
  position: relative;
  padding: 10px;
  border: ${({ $active }) => ($active ? '2.5px solid #fe6f5f' : 'none')};
  border-radius: 50%;
  cursor: pointer;
  width: ${({ $active }) => ($active ? '150px' : '100px')};
  height: ${({ $active }) => ($active ? '150px' : '100px')};
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-y: 18px;
  background-color: aliceblue;
  background-clip: ${({ $active }) => ($active ? 'content-box' : 'none')};
  filter: grayscale(${({ $active }) => ($active ? '0' : '90%')});
  margin-left: ${({ $active }) => ($active ? '-25px' : '0')};

  &:after {
    position: absolute;
    display: ${({ $active }) => ($active ? 'inline-block' : 'none')};
    content: '';
    height: 2px;
    width: 83px;
    background: #fe6f5f;
    bottom: 50%;
    right: -57%;
  }

  @media (max-width: 1100px) {
    margin-left: 0;

    &:after {
      height: 57px;
      width: 2px;
      bottom: -34%;
      right: 50%;
    }
  }

  @media (max-width: 780px) {
    width: ${({ $active }) => ($active ? '130px' : '60px')};
    height: ${({ $active }) => ($active ? '130px' : '60px')};

    background-position-y: ${({ $active }) => ($active ? '14px' : '9px')};

    :after {
      bottom: -38%;
    }
  }

  @media (max-width: 660px) {
    width: ${({ $active }) => ($active ? '100px' : '50px')};
    height: ${({ $active }) => ($active ? '100px' : '50px')};

    :after {
      height: 45px;
    }
  }

  @media (max-width: 450px) {
    width: ${({ $active }) => ($active ? '54px' : '39px')};
    height: ${({ $active }) => ($active ? '54px' : '39px')};
    padding: 5px;
    background-position-y: 5px;

    :after {
      height: 26px;
      width: 2px;
      bottom: -43%;
      right: 50%;
    }
  }
`;

export const MentorImage = styled(motion.img)`
  position: relative;
  z-index: -1;
  background: transparent;
  //max-width: 416px;
  max-width: 506px;
  max-height: 442px;
`;

export const MentorImageWrapper = styled(motion.div)`
  background: transparent;
  box-shadow: inset 0 -543px 78px -420px #1c1c1c;
  min-height: 445px;

  @media (max-width: 1100px) {
    min-height: 315px;

    ${MentorImage} {
      min-width: 347px;
      max-width: 349px;
    }
  }
  @media (max-width: 750px) {
    min-height: 266px;

    ${MentorImage} {
      min-width: 288px;
      max-width: 288px;
    }
  }
  @media (max-width: 600px) {
    min-height: 232px;

    ${MentorImage} {
      min-width: 230px;
      max-width: 230px;
    }
  }
  @media (max-width: 450px) {
    min-height: 140px;
    box-shadow: inset 0 -524px 56px -489px #1c1c1c;

    ${MentorImage} {
      position: absolute;
      max-width: 215px;
      min-width: 215px;
      min-height: 164px;
      top: ${({ $isActiveId }) => ($isActiveId === 2 ? '20px' : 'unset')};
    }
  }
`;

export const AboutMentorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  z-index: 10;
  position: relative;

  @media (max-width: 1100px) {
    flex-direction: row;
    min-height: 400px;
  }
  @media (max-width: 750px) {
    min-height: 328px;
  }
  @media (max-width: 600px) {
    min-height: 252px;
  }
  @media (max-width: 450px) {
    width: 100vw;
    top: 4px;
    left: -25px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
    position: relative;
  }
`;

export const InfoWrapper = styled(motion.div)`
  @media (max-width: 450px) {
    position: absolute;
    right: 0;
    max-width: 200px;
  }
`;

export const SocialWrapper = styled.div`
  max-width: 105px;
`;

export const MentorName = styled.div`
  font-weight: 900;
  font-size: ${({ theme }) => theme.typography.size[7]};
  color: ${({ theme }) => theme.colors.text.main};
  padding-left: 20px;

  @media (max-width: 1100px) {
    padding-left: 0;
    font-size: 25px;
  }
  @media (max-width: 780px) {
    font-size: 20px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
  }
`;

export const MentorDescription = styled.div`
  font-weight: 400;
  font-size: 23px;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.text.lightGrey};
  max-width: 670px;

  @media (max-width: 1100px) {
    font-size: 19px;
    position: relative;
    z-index: 11;
  }
  @media (max-width: 870px) {
    font-size: 16px;
  }
  @media (max-width: 750px) {
    font-size: 14px;
  }
  @media (max-width: 650px) {
    font-size: 12px;
  }

  @media (max-width: 450px) {
    position: relative;
    z-index: 11;
  }
`;

export const SocialIcon = styled.div`
  position: relative;
  z-index: 10000;
  cursor: pointer;
  background-image: url(${({ src }) => src});
  width: ${({ youtube }) => (youtube ? '52px' : '134px')};
  height: ${({ youtube }) => (youtube ? '37px' : '62px')};
  margin-top: ${({ youtube }) => (youtube ? '20px' : '0')};
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: 650px) {
    height: ${({ youtube }) => (youtube ? '20px' : '44px')};
  }
`;

export const ImagesWrapper = styled.ul`
  margin-top: 85px;
  position: relative;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  z-index: 10;

  @media (max-width: 1100px) {
    flex-direction: row;
    padding-left: 50px;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 30px;
  }
  @media (max-width: 780px) {
    margin-bottom: 30px;
    padding-left: 20px;
    justify-content: center;
  }
  @media (max-width: 450px) {
    flex-direction: row;
    margin-bottom: 0;
    align-items: center;
  }
`;

export const BackgroundFlash = styled.div`
  width: 400px;
  height: 160px;
  position: absolute;
  z-index: -1;
  right: 27%;
  top: 0;
  border-radius: 407px;
  background: linear-gradient(180deg, #1f95b7 0%, #8123a1 100%);
  filter: blur(96px);
  transform: translate3d(0, 0, 0);
`;

export const SecondBackgroundFlash = styled.div`
  width: 341px;
  height: 120px;
  position: absolute;
  z-index: -1;
  right: 25%;
  top: 76px;
  border-radius: 351.547px;
  background: rgba(217, 217, 217, 0.51);
  filter: blur(104px);
  transform: translate3d(0, 0, 0);
`;

export const FooterBackgroundFlash = styled.img`
  position: absolute;
  left: 0;
  z-index: 10;
  width: 100%;

  @media (max-width: 1100px) {
    left: 0;
    z-index: 10;
    bottom: -36%;
  }

  @media (max-width: 450px) {
    left: 0;
    bottom: -30px;
    z-index: 10;
  }
`;
