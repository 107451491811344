import emailjs from '@emailjs/browser';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import tgIcon from '@/assets/icons/tg.svg';
import offerta from '@/assets/offerta.pdf';
import { menuItemsConfig } from '@/components/ApplicationForm/config';

import * as S from './components';

export const ApplicationForm = React.memo(
  ({ isModal, learningPackage, setVisibleModal }) => {
    const [state, setState] = useState({
      name: '',
      phone: '@',
      message: '',
      socialName: 'telegram',
    });
    const { name, phone, message, socialName } = state;
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [controlImage, setControlImage] = useState(tgIcon);

    const notify = () =>
      toast.success('Cпасибо! Ваш запрос будет обработан в ближайшее время!');

    const sendEmail = (e) => {
      e.preventDefault();

      emailjs
        .send(
          'StationMessages',
          'StationTemplate',
          { name, phone, message, socialName, learningPackage },
          'ZaDBCvpTUJiggf-xD',
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          },
        )
        .then(notify);
      setState({ ...state, name: '', message: '' });

      if (isModal) {
        setVisibleModal(false);
      }
    };

    const onChangeSocial = (prefix, image, socialName) => {
      setState({
        ...state,
        phone: prefix,
        socialName,
      });
      setControlImage(image);
      setIsOpenMenu(!isOpenMenu);
    };

    return (
      <S.GetStartedForm onSubmit={sendEmail}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <S.InputsWrapper>
          <S.InputWrapper>
            <S.Input
              type="text"
              placeholder="Имя"
              name="name"
              value={name}
              onChange={(e) =>
                setState({ ...state, name: e.currentTarget.value })
              }
              required
            />
          </S.InputWrapper>

          {isModal && (
            <S.SelectWrapper>
              <S.Select>
                <option value="BASIC" selected={'BASIC' === learningPackage}>
                  BASIC
                </option>

                <option
                  value="BASIC | ENGLISH"
                  selected={'BASIC | ENGLISH' === learningPackage}
                >
                  BASIC | ENGLISH
                </option>

                <option
                  value="JUNIOR+"
                  selected={'JUNIOR+' === learningPackage}
                >
                  JUNIOR+
                </option>

                <option
                  value="JUNIOR+ | ENGLISH"
                  selected={'JUNIOR+ | ENGLISH' === learningPackage}
                >
                  JUNIOR+ | ENGLISH
                </option>
              </S.Select>
            </S.SelectWrapper>
          )}
          <S.InputWrapper>
            <S.ControlWrapper>
              <S.Control
                onClick={() => setIsOpenMenu(!isOpenMenu)}
                controlImage={controlImage}
              />
              {isOpenMenu && (
                <S.MenuWrapper>
                  <S.Menu>
                    {menuItemsConfig.map(({ name, prefix, icon }) => (
                      <S.MenuItem
                        socialImage={icon}
                        key={name}
                        onClick={() => onChangeSocial(prefix, icon, name)}
                      />
                    ))}
                  </S.Menu>
                </S.MenuWrapper>
              )}
              <S.SocialInput
                value={state.phone}
                name="phone"
                onChange={(e) =>
                  setState({
                    ...state,
                    phone: e.currentTarget.value,
                  })
                }
                required
              />
            </S.ControlWrapper>
          </S.InputWrapper>

          <S.CommentsWrapper>
            <S.Comments
              placeholder="Комментарий..."
              value={message}
              name="message"
              onChange={(e) =>
                setState({ ...state, message: e.currentTarget.value })
              }
            />
          </S.CommentsWrapper>

          <S.RulesWrapper>
            <S.Checkbox type="checkbox" required />
            <S.Rules>
              Я ознакомился c{' '}
              <a href={offerta} target="_blank" rel="noreferrer">
                <span>договором оферты</span>
              </a>{' '}
              и согласен на обработку персональных данных
            </S.Rules>
          </S.RulesWrapper>
        </S.InputsWrapper>

        <S.ButtonStart>Консультация</S.ButtonStart>
      </S.GetStartedForm>
    );
  },
);
