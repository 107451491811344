export const courseFeaturesConfig = [
  {
    title: 'Актуальная программа',
    specialBlockMobile: true,
    description:
      'Мы следим за трендами и обновляем программу перед каждый набором',
  },
  { specialBlock: true },
  {
    title: 'Востребованные навыки ',
    specialBlockMobile: true,
    description:
      'Мы проанализировали 2000 вакансий и определили навыки, которые нужны всем - теперь они в нашей программе ',
  },
  { specialBlock: true },
  {
    title: 'Командная работа ',
    specialBlockMobile: true,
    description:
      'Прокачаете soft-скилы, которые обязательно проверят на собеседовании',
  },
];
