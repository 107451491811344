import styled from 'styled-components/macro';

import arrow from '@/assets/icons/arrowDown.svg';
import { Container } from '@/components/Container';

export const Section = styled.section`
  @media (min-width: 1100px) {
    min-height: 850px;
  }
`;

export const Description = styled.div`
  font-size: ${({ theme }) => theme.typography.size[4]};
  color: ${({ theme }) => theme.colors.text.additional};
  max-width: 164px;
  text-align: center;
  user-select: none;

  @media (max-width: 650px) {
    font-size: 19px;
  }
  @media (max-width: 620px) {
    font-size: 16px;
    max-width: 104px;
  }
  @media (max-width: 500px) {
    max-width: 73px;
  }
  @media (max-width: 450px) {
    max-width: 147px;
    font-size: 13px;
  }
`;

export const RevertDescription = styled(Description)`
  display: flex;
  align-items: end;
  margin-bottom: 137px;
`;

export const DevopsTitle = styled.h1`
  font-family: Generator, sans-serif;
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.size[17]};
  color: ${({ theme }) => theme.colors.text.main};
  text-transform: uppercase;
  position: absolute;
  z-index: 1;
  letter-spacing: 22px;
  pointer-events: none;

  @media (max-width: 886px) {
    font-size: 127px;
  }
  @media (max-width: 650px) {
    font-size: 104px;
    letter-spacing: 13px;
  }
  @media (max-width: 560px) {
    font-size: 92px;
  }
  @media (max-width: 500px) {
    font-size: 72px;
  }
  @media (max-width: 456px) {
    font-size: 68px;
    letter-spacing: 11px;
  }
`;

export const ProgramTitle = styled(DevopsTitle)`
  z-index: 3;
  letter-spacing: 18px;

  @media (max-width: 650px) {
    letter-spacing: 13px;
  }
  @media (max-width: 456px) {
    letter-spacing: 14px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin-left: -200px;
  @media (max-width: 1000px) {
    margin-left: -367px;
  }
  @media (max-width: 750px) {
    margin-left: -200px;
  }
`;

export const FirstImage = styled.img`
  width: 100%;

  ${({ theme }) => theme.below.s`
    width: 90%;
  `};

  @media (max-width: 650px) {
    width: 66%;
    bottom: 0;
    position: relative;
    left: 12%;
  }
  @media (max-width: 500px) {
    width: 57%;
    left: 20%;
  }

  @media (max-width: 450px) {
    width: 225px;
    left: 20%;
  }

}

`;

export const SecondMainImage = styled.img`
  position: absolute;
  bottom: -33px;
  right: -173px;
  z-index: 2;
  width: 377px;
  transform: rotate(7deg);

  ${({ theme }) => theme.below.m`
    bottom: 13px;
  `}

  ${({ theme }) => theme.below.s`
    width: 75%;
    right: -110px;
  `};

  @media (max-width: 650px) {
    right: -18px;
    width: 55%;
    bottom: 35px;
  }
  @media (max-width: 500px) {
    right: 11px;
    width: 51%;
    bottom: 7px;
  }

  @media (max-width: 450px) {
    width: 174px;
    right: -107px;
  }
`;
export const Circle = styled.div`
  position: absolute;
  color: #ebebeb;
  font-size: 18px;
  bottom: 6px;
  left: 120px;
  cursor: pointer;
  z-index: 10;

  &:after {
    content: '';
    background-image: url(${arrow});
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 23px 75px;
    width: 24px;
    height: 142px;
    margin-top: -131px;
  }

  @media (max-width: 650px) {
    font-size: 16px;
  }

  @media (max-width: 560px) {
    left: 144px;
  }
  @media (max-width: 500px) {
    left: 194px;
  }

  @media (max-width: 450px) {
    bottom: 0;
    left: 85px;
    font-size: 9px;
    &:after {
      background-size: 19px 36px;
      width: 17px;
      height: 77px;
      margin-top: -64px;
    }
  }
`;

export const MainContainer = styled(Container)`
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 760px;
  padding: 70px 0 0;

  @media (max-width: 650px) {
    min-height: 600px;
  }
  @media (max-width: 500px) {
    min-height: 545px;
  }
  @media (max-width: 450px) {
    min-height: 420px;
  }
`;

export const BackgroundFlash = styled.img`
  position: absolute;
  left: -55%;
  z-index: -1;

  @media (max-width: 500px) {
    max-width: 550px;
    right: -40px;
    bottom: -40px;
  }
  @media (max-width: 450px) {
    max-width: 400px;
    right: -40px;
  }
`;
export const SecondBackgroundFlash = styled(BackgroundFlash)`
  left: -25%;
  bottom: -35%;

  @media (max-width: 500px) {
    left: 35px;
    bottom: -45px;
    max-width: 300px;
  }
  @media (max-width: 450px) {
    left: -45px;
  }
`;

export const Button = styled.div`
  border: 2px solid rgba(254, 111, 95, 1);
  border-radius: 12px;
  padding: 5px 15px 5px 15px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #fe6f5f;
  cursor: pointer;
  z-index: 1000;
  position: relative;
  top: 30px;
  right: 20px;
  max-width: 155px;
  margin-left: auto;

  @media (min-width: 450px) {
    display: none;
  }
`;
