import React, { createContext, useState } from 'react';

export const ModalContext = createContext({
  visible: false,
  setVisible: () => {},
  package: '',
});

export const ModalProvider = React.memo(({ children }) => {
  const [visible, setVisible] = useState(false);
  const [learningPackage, setLearningPackage] = useState('');

  const onSetVisibleHandler = (visible) => {
    setVisible(visible);
  };

  return (
    <ModalContext.Provider
      value={{
        visible: visible,
        learningPackage: learningPackage,
        changeLearningPackage: setLearningPackage,
        setVisible: onSetVisibleHandler,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
});
