import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.size[4]};
  //TODO
  background: linear-gradient(89.97deg, #fe6f5f -4.83%, #ff2e16 105.37%);
  max-width: 326px;
  min-height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text.main};
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 0px 13px 4px rgba(222, 73, 55, 0.2);
  box-sizing: border-box;
  border: none;
  padding: 20px 80px;

  @media (max-width: 600px) {
    max-width: 246px;
    font-size: 16px;
    min-height: 46px;
  }
`;
