import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

import cross from '@/assets/icons/cross.svg';
import { ArticleTitle } from '@/components/Devops/components';

export const Section = styled.section`
  position: relative;
  margin-top: 35px;
  margin-bottom: 150px;

  @media (min-width: 1100px) {
    min-height: 980px;
  }
  @media (max-width: 450px) {
    margin-bottom: 55px;
  }
`;

export const Article = styled(ArticleTitle)`
  margin-bottom: 55px;
  @media (max-width: 450px) {
    margin-bottom: 15px;
  }
`;

export const ArticleDescription = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.size[7]};
  color: ${({ theme }) => theme.colors.text.lightGrey};
  max-width: 870px;

  @media (max-width: 1100px) {
    font-size: 16px;
    max-width: 425px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
    line-height: 120%;
    max-width: 281px;
    margin-bottom: 20px;
  }
`;

export const FeaturesWrapper = styled(motion.ul)`
  position: relative;
  margin-top: 65px;
  margin-bottom: 96px;
  min-height: 760px;

  @media (max-width: 1100px) {
    min-height: initial;
  }

  @media (max-width: 450px) {
    margin-top: 0;
    margin-bottom: 40px;
  }
`;

export const Cross = styled(motion.div)`
  position: relative;
  background-image: url(${cross});
  background-repeat: no-repeat;
  background-position: center;
  width: 15px;
  height: 15px;
  cursor: pointer;

  @media (max-width: 600px) {
    width: 10px;
    height: 10px;
  }
`;

export const CrossWrapper = styled(motion.div)`
  margin-left: auto;
  margin-right: 15px;
`;

export const FeatureTitle = styled(motion.div)`
  position: relative;
  font-weight: 700;
  font-size: ${({ theme }) => theme.typography.size[4]};
  color: inherit;
  padding: 42px 10px 32px 15px;
  max-width: 326px;
  min-width: 326px;
  box-sizing: border-box;
  transition: color 0.5s ease-in;

  &:after {
    content: '${({ $number }) => $number}';
    width: 19px;
    height: 19px;
    font-weight: 500;
    font-size: ${({ theme }) => theme.typography.size[1]};
    //  TODO
    color: #3783cf;
    position: absolute;
    top: 30px;
    right: ${({ $numberPosition }) =>
      $numberPosition ? $numberPosition : '-10px'};
  }

  @media (max-width: 1100px) {
    font-size: 20px;
    max-width: 386px;
    min-width: unset;
  }
  @media (max-width: 600px) {
    font-size: 15px;
    max-width: 297px;
  }
  @media (max-width: 450px) {
    font-size: 16px;
    max-width: 270px;
    padding: 8px 10px 8px 0;

    :after {
      font-size: 10px;
      top: 6px;
      right: -12px;
    }
  }
`;

export const Feature = styled(motion.li)`
  position: relative;
  display: flex;
  align-items: center;
  // TODO
  border-bottom: 1px solid #535353;
  color: ${({ theme }) => theme.colors.text.main};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.text.darkGrey};

    ${Cross} {
      filter: brightness(0);
    }
  }

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  @media (max-width: 650px) {
    &:hover {
      color: white;

      ${Cross} {
        filter: none;
      }
    }
  }

  @media (max-width: 450px) {
    &:last-child {
      ${FeatureTitle} {
        :after {
          right: 43px;
        }
      }
    }

    &:nth-child(2) {
      ${FeatureTitle} {
        :after {
          right: 37px;
        }
      }
    }
  }
`;

export const FeatureDescription = styled(motion.div)`
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.size[2]};
  max-width: 476px;
  padding: 32px 0 32px 150px;
  transition: color 0.5s ease-in;

  @media (max-width: 1100px) {
    font-size: 16px;
    padding: 24px 66px 32px 12px;
    max-width: none;
  }
  @media (max-width: 600px) {
    font-size: 12px;
    color: white;
  }
  @media (max-width: 450px) {
    font-size: 12px;
    line-height: 120%;
    padding: 0 24px 12px 0;
  }
}
`;

export const Background = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 1px;
  left: 0;
  background-color: ${({ theme }) => theme.colors.text.main};
  z-index: -1;
`;

export const FlashWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 20%;

  @media (max-width: 450px) {
    top: -200px;
    left: 0;
    right: unset;
  }
`;

export const BackgroundFlash = styled.div`
  position: absolute;
  width: 164px;
  height: 251px;
  top: 105px;
  left: 45%;
  border-radius: 299px;
  background: linear-gradient(180deg, #5f0e91 0%, #1f9bbd 100%);
  z-index: -1;
`;

export const SecondBackgroundFlash = styled(BackgroundFlash)`
  top: 310px;
  left: 35%;

  @media (max-width: 450px) {
    left: 0;
  }
`;

export const ThirdBackgroundFlash = styled(BackgroundFlash)`
  width: 291px;
  height: 286px;
  border-radius: 449px;
  background: linear-gradient(180deg, #1f95b7 0%, #8123a1 100%);
  left: unset;
  top: unset;
  right: 0;
  bottom: 33%;

  @media (max-width: 450px) {
    top:  400px;
  }
`;

export const BackgroundFlashWrapper = styled.div`
  position: absolute;
  height: 850px;
  width: 100%;
  top: -200px;
  left: 0;
  filter: blur(119px);
  transform: translate3d(0, 0, 0);
  z-index: -1;
`;
