import { useMediaQuery } from 'react-responsive';

export const CurveText = ({
  children,
  height,
  width,
  radius,
  id,
  offset = '50%',
  second,
}) => {
  const isLaptop = useMediaQuery({ query: '(max-width: 800px)' });
  const isLaptopL = useMediaQuery({ query: '(max-width: 750px)' });
  const isLaptopM = useMediaQuery({ query: '(max-width: 650px)' });
  const isLaptopS = useMediaQuery({ query: '(max-width: 560px)' });
  const isLaptopXS = useMediaQuery({ query: '(max-width: 500px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 450px)' });
  const vbox = [0, 0, width, height].join(' ');
  let x = 715;
  let y = 200;
  let rHeight = 1880;

  if (isLaptop) {
    x = 654;
  }
  if (isLaptopL) {
    x = 784;
  }
  if(isLaptopM){
    rHeight = 1722
    x = 694
    y = 300
  }
  if(isLaptopS){
    x = 644
    y = 300
  }
  if(isLaptopXS) {
    rHeight = 1472
    x = 864
  }
  if (isMobile) {
    y = 700;
    x = 813
    rHeight = 550
  }

  function circle(cx, cy, r, deg) {
    var theta = (deg * Math.PI) / 180,
      dx = r * Math.cos(theta),
      dy = -r * Math.sin(theta);
    return (
      'M ' +
      cx +
      ' ' +
      cy +
      'm ' +
      dx +
      ',' +
      dy +
      'a ' +
      r +
      ',' +
      r +
      ' 0 1,0 ' +
      -2 * dx +
      ',' +
      -2 * dy +
      'a ' +
      r +
      ',' +
      r +
      ' 0 1,0 ' +
      2 * dx +
      ',' +
      2 * dy
    );
  }

  const d = circle(width / 2, height / 2, radius, 360);
  return (
    <svg viewBox={vbox} width={width} height={height}>
      <g>
        <path stroke="stroke" d={d} id={id} fill="none" />
        <text>
          <textPath
            xlinkHref={`#${id}`}
            fill="white"
            startOffset={offset}
            textAnchor="middle"
          >
            {children}
          </textPath>
        </text>
      </g>
      {second && (
        <rect
          fill="#1c1c1c"
          x={x}
          y={y}
          // x={isMobile ? '813' : '715'}
          // y={isMobile ? '700' : '200'}
          width="600"
          height={rHeight}
          // height={isMobile ? '550' : '1880'}
          opacity="0.5"
          id="svgBlock"
        />
      )}
    </svg>
  );
};
