import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-scroll';

import bePaid from '@/assets/bePaid.pdf';
import metaFooterIcons from '@/assets/icons/meta-footer-icons.svg';
import logo from '@/assets/images/logo.png';
import offerta from '@/assets/offerta.pdf';
import policy from '@/assets/policy.pdf';

import * as S from './components';

export const Footer = React.memo(() => {
  const isLaptop = useMediaQuery({ query: '(max-width: 900px)' });
  return (
    <S.Section>
      <S.Communication>
        <S.MetaWrapper>
          <S.Logo src={logo} alt="logo-image" />

          <S.ContactsWrapper>
            <S.ContactsDataWrapper>
              <div>+375 29 509 29 06</div>
              <div>itstation.lab@gmail.com</div>
            </S.ContactsDataWrapper>

            <S.IconsWrapper>
              <a
                href="https://www.instagram.com/it_station/"
                target="_blank"
                rel="noreferrer"
              >
                <S.Icon />
              </a>
              <a
                href="https://t.me/ITstation_lab"
                target="_blank"
                rel="noreferrer"
              >
                <S.TgIcon />
              </a>
            </S.IconsWrapper>
          </S.ContactsWrapper>
        </S.MetaWrapper>

        {isLaptop ? (
          <S.FeaturesWrapper>
            <S.FeaturesDataWrapper>
              <Link to="education">
                <S.Feature> Как проходит обучение</S.Feature>
              </Link>
              <S.Feature>
                <a href={offerta} target="_blank" rel="noreferrer">
                  Договор оферты
                </a>
              </S.Feature>
              <S.Feature>
                <a href={policy} target="_blank" rel="noreferrer">
                  Политика конфиденциальности
                </a>
              </S.Feature>
              <S.Feature>
                <a href={bePaid} target="_blank" rel="noreferrer">
                  Способы оплаты bePaid
                </a>
              </S.Feature>
              <div>ИП Шнипов Никита Игоревич</div>
              <div>УНП 491463057 от 12.07.2021</div>
            </S.FeaturesDataWrapper>
          </S.FeaturesWrapper>
        ) : (
          <S.FeaturesWrapper>
            <S.FeaturesDataWrapper>
              <Link to="education">
                <S.Feature>Как проходит обучение</S.Feature>
              </Link>
              <Link to="program">
                <S.Feature>Программа обучения</S.Feature>
              </Link>
              <Link to="ourTeam">
                <S.Feature> Наша команда</S.Feature>
              </Link>
            </S.FeaturesDataWrapper>

            <S.FeaturesDataWrapper>
              <S.Feature>
                <a href={offerta} target="_blank" rel="noreferrer">
                  Публичный договор
                </a>
              </S.Feature>

              <S.Feature>
                <a href={policy} target="_blank" rel="noreferrer">
                  Политика конфиденциальности
                </a>
              </S.Feature>

              <S.Feature>
                <a href={bePaid} target="_blank" rel="noreferrer">
                  Способы оплаты bePaid
                </a>
              </S.Feature>
            </S.FeaturesDataWrapper>

            <S.FeaturesDataWrapper>
              <div>Юридическая информация:</div>
              <div>ИП Шнипов Никита Игоревич</div>
              <div>УНП 491463057 от 12.07.2021</div>
            </S.FeaturesDataWrapper>
          </S.FeaturesWrapper>
        )}
      </S.Communication>

      <S.RightsWrapper>
        <img src={metaFooterIcons} alt="meta-icons" />

        <S.Rights>© 2023 Все права защищены.</S.Rights>
      </S.RightsWrapper>
    </S.Section>
  );
});
