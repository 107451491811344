import styled from 'styled-components/macro';

import { ArticleTitle } from '@/components/Devops/components';

export const Section = styled.section`
  margin-top: 200px;
  margin-bottom: 100px;
  @media (min-width: 1100px) {
    min-height: 520px;
  }
  @media (max-width: 450px) {
    margin-top: 15px;
    margin-bottom: 20px;
  }
`;

export const Article = styled(ArticleTitle)`
  position: absolute;
  top: -85px;
  left: 90px;
  line-height: 73px;
  text-align: center;
  margin: 0;
  z-index: 2;

  @media (max-width: 450px) {
    text-align: end;
    top: 54px;
    line-height: 38px;
    font-size: 45px;
    left: 53px;
    min-width: 279px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`;

export const Image = styled.img`
  max-height: 528px;

  @media (max-width: 450px) {
    max-width: 270px;
    margin-left: -165px;
    z-index: 1;
  }
`;

export const BackgroundFlash = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
  width: 326px;
  height: 361px;
  rotate: -38.18deg;
  border-radius: 361px;
  background: linear-gradient(180deg, #1f95b7 0%, #8123a1 100%);
  filter: blur(100px);
  transform: translate3d(0, 0, 0);
`;
