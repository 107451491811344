import React from 'react';
import { useMediaQuery } from 'react-responsive';

import backgroundImageDevops from '@/assets/images/backgroundDevops.png';
import { Container } from '@/components/Container';
import { Feature } from '@/components/Devops/Feature';
import { MobileSalary } from '@/components/Devops/MobileSalary';
import { devopsConfig } from '@/components/Devops/config';
import { HIDDEN, VISIBLE } from '@/constants/framer';

import * as S from './components';
import { salaries } from './config';

export const Devops = React.memo(() => {
  const titleAnimation = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.5,
      },
    },
  };
  const descriptionAnimation = {
    hidden: {
      opacity: 0,
      x: 200,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.5,
      },
    },
  };

  const articleAnimation = {
    hidden: {
      opacity: 0,
      x: -600,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.5,
      },
    },
  };

  const secondArticleAnimation = {
    hidden: {
      opacity: 0,
      x: -600,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 2.5,
      },
    },
  };

  const columnAnimation = {
    hidden: {
      height: 0,
    },
    visible: (custom) => ({
      height: 'auto',
      transition: {
        delay: custom * 0.2,
        duration: 1,
      },
    }),
  };

  const salaryAnimation = {
    hidden: {
      opacity: 0,
    },
    visible: (custom) => ({
      opacity: 1,
      transition: {
        delay: custom * 0.27,
        duration: 1.5,
      },
    }),
  };

  const isLaptop = useMediaQuery({ query: '(max-width: 560px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 450px)' });

  return (
    <S.Section initial={HIDDEN} whileInView={VISIBLE} viewport={{ once: true }}>
      <S.BackgroundFlashWrapper>
        <S.BackgroundFlash />
      </S.BackgroundFlashWrapper>

      <Container>
        <S.TitleWrapper>
          <S.Title variants={isMobile ? 'none' : titleAnimation}>
            DevOps
          </S.Title>
          <S.Description variants={isMobile ? 'none' : descriptionAnimation}>
            инженеры контролируют все этапы создания продукта: от написания кода
            до релиза. Помогают отделам разработки и администрирования,
            синхронизируют их усилия и автоматизируют технические процессы. За
            последние годы спрос на этих специалистов вырос в несколько раз. Мы
            поможем стать DevOps-инженером с нуля и найдём вам работу.
          </S.Description>
          <S.BackgroundTitle>DevOps</S.BackgroundTitle>
        </S.TitleWrapper>

        <S.ArticleTitle variants={isMobile ? 'none' : articleAnimation}>
          Как это будет
        </S.ArticleTitle>

        <S.FeaturesWrapper>
          {devopsConfig.map(
            ({ number, title, description, maxWidth, textAlign, custom }) => (
              <Feature
                key={number}
                number={number}
                title={title}
                description={description}
                maxWidth={maxWidth}
                textAlign={textAlign}
                custom={custom}
              />
            ),
          )}
        </S.FeaturesWrapper>

        <S.SecondArticle variants={isMobile ? 'none' : secondArticleAnimation}>
          Востребованность на рынке
        </S.SecondArticle>

        {!isLaptop && (
          <S.SalaryWrapper>
            <S.BackgroundImage
              src={backgroundImageDevops}
              alt="backgroundImage"
            />

            <S.SalaryTabletsWrapper>
              {salaries.map(({ salary, position }, i) => (
                <S.SalaryTablet
                  index={i}
                  key={salary}
                  variants={columnAnimation}
                  initial={HIDDEN}
                  whileInView={VISIBLE}
                  custom={i}
                  viewport={{ once: true }}
                >
                  <S.Salary
                    index={i}
                    variants={salaryAnimation}
                    initial={HIDDEN}
                    whileInView={VISIBLE}
                    custom={i + 2}
                    viewport={{ once: true }}
                  >
                    {salary}
                  </S.Salary>

                  <S.Position
                    variants={salaryAnimation}
                    initial={HIDDEN}
                    whileInView={VISIBLE}
                    custom={i + 2.7}
                    viewport={{ once: true }}
                  >
                    {position}
                  </S.Position>
                </S.SalaryTablet>
              ))}
            </S.SalaryTabletsWrapper>

            <S.VacanciesNote>
              * >2000 вакансий DevOps Engineer link hh.ru
            </S.VacanciesNote>
          </S.SalaryWrapper>
        )}
      </Container>
      {isLaptop && <MobileSalary />}
    </S.Section>
  );
});
