import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';

import * as S from './components';

export const Accordion = React.memo(({ courseFeatures }) => {
  const [isOpenDescription, setIsOpenDescription] = useState(1);

  return courseFeatures.map(({ featureTitle, featureDescription, id }) => {
    const active = isOpenDescription === id;
    return (
      <S.CourseFeatureWrapper key={id}>
        <S.FeatureTitleWrapper
          onClick={
            featureDescription
              ? () => setIsOpenDescription(active ? undefined : id)
              : null
          }
        >
          <S.CourseFeatureTitle>{featureTitle}</S.CourseFeatureTitle>

          {featureDescription && (
            <S.CourseCross
              animate={
                active
                  ? { rotate: 135, transition: { duration: 0.5 } }
                  : { rotate: 0, transition: { duration: 0.5 } }
              }
              onClick={() => setIsOpenDescription(active ? undefined : id)}
            />
          )}
        </S.FeatureTitleWrapper>

        <AnimatePresence initial={false}>
          {active && featureDescription && (
            <S.CourseFeatureDescription
              key={id}
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: 1,
                height: 'auto',
                scale: 1,
                marginTop: '10px',
                marginBottom: '10px',
              }}
              exit={{
                opacity: 0,
                height: 0,
                margin: 0,
                transition: {
                  opacity: {
                    duration: 0.35,
                  },
                  height: {
                    duration: 0.5,
                  },
                },
              }}
              transition={{ duration: 0.5 }}
              dangerouslySetInnerHTML={{ __html: featureDescription }}
            />
          )}
        </AnimatePresence>
      </S.CourseFeatureWrapper>
    );
  });
});
