import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import { App } from '@/components/App';
import { IOSVersionProvider } from '@/context/IOSVersionContext';
import { ModalProvider } from '@/context/ModalContext';
import { GlobalStyle } from '@/styles/globalStyle';
import { theme } from '@/styles/theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <ModalProvider>
      <IOSVersionProvider>
        <GlobalStyle />
        <App />
      </IOSVersionProvider>
    </ModalProvider>
  </ThemeProvider>,
);
export { useScrollPosition } from '@/hooks/useScrollPosition';
