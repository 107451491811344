export const levelsConfig = [
  {
    number: '01.',
    title: 'Новичкам',
    description:
      'Мы одни из немногих школ, кто обучает DevOps с нуля. Даже если вы никогда не работали в IT, мы обучим вас этой востребованной и высокооплачиваемой профессии.',
  },
  {
    number: '02.',
    title: 'Начинающим',
    description:
      'Вы уже имеете опыт написания простеньких скриптов и работы с UNIX системами? Мы поможем разобраться в микросервисной архитектуре, научим разворачивать инфраструктуру, используя оркестраторы и сервисы облачных провайдеров.',
  },
  {
    number: '03.',
    title: 'Практикующим',
    description:
      'Вы сможете переквалифицироваться с должности системного администратора или разработчика в DevOps-инженера. Вы сможете выполнять более сложные задачи и повысите свою стоимость как специалиста.',
    width: 360,
  },
];
