import styled from 'styled-components/macro';

import {
  BackgroundImage,
  Position,
  SalaryTablet,
  SalaryTabletsWrapper,
  VacanciesNote,
} from '@/components/Devops/components';

export const Wrapper = styled.div``;

export const MBackgroundImage = styled(BackgroundImage)`
  bottom: -16%;
`;

export const MSalaryTabletsWrapper = styled(SalaryTabletsWrapper)`
  padding-left: 5px;
  padding-right: 5px;
  gap: 0;
`;

export const MSalaryTablet = styled(SalaryTablet)`
  border-radius: 10px;
  min-width: 79px;
  padding: 12px 5px 15px;
`;

export const MSalary = styled.div`
  color: #fff;
  font-weight: 700;
  line-height: 160%;
  font-size: 10px;
`;

export const MPosition = styled(Position)`
  font-size: 10px;
`;

export const MVacanciesNote = styled(VacanciesNote)``;

export const MSalaryWrapper = styled.div`
  margin: ${({ theme, index }) => `${theme.margin.mobileSalaryTablets[index]}`};
`;
