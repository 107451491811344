import styled from 'styled-components/macro';

import {
  BackgroundFlash,
  EnglishButton,
  EnglishFeatureDescription,
  EnglishFeatureNumber,
  EnglishFeatureTitle,
  EnglishTitle,
} from '@/components/PackageCost/components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const EnglishTitleML = styled(EnglishTitle)`
  margin-bottom: 40px;
  @media (max-width: 450px) {
    font-size: 45px;
  }
`;

export const EnglishFeatureML = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 40px;
`;

export const EnglishFeatureTitleML = styled(EnglishFeatureTitle)`
  @media (max-width: 450px) {
    font-size: 20px;
  }
`;

export const EnglishFeatureDescriptionML = styled(EnglishFeatureDescription)`
  @media (max-width: 450px) {
    font-size: 14px;
    max-width: 309px;
  }
`;

export const EnglishFeatureNumberML = styled(EnglishFeatureNumber)`
  @media (max-width: 450px) {
    font-size: 150px;
    right: -20px;

    &:nth-child(2n) {
      right: 200px;
    }
  }
`;

export const BackgroundFlashML = styled(BackgroundFlash)``;

export const EnglishButtonML = styled(EnglishButton)``;
